import React, { useContext, useReducer, useEffect, useState } from "react"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"

import { I18n } from "aws-amplify"
import { API, graphqlOperation } from "aws-amplify"
import {
  listItemsBySection,
  listSections,
  listCategories,
  listBrands,
} from "../../graphql/queries"
import { editItemPrice, setMaxInCart } from "../../graphql/mutations"

import GlobalContext from "../../context/global-context"
import itemsReducer from "../../reducers/items"
import Item from "./Item"
import EditItemSetupDialog from "./EditItemSetupDialog"
import SetMaxInCartDialo from "./SetMaxInCartDialog"
import { Button } from "@material-ui/core"
import MangeItemDiscounts from "./MangeItemDiscounts"
import { getFormattedShortDate } from "../../utils/dateUtil"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  gridItem: {
    width: 150,
  },
  grid: {
    padding: theme.spacing(5),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tableWrapper: {
    maxHeight: 600,
    overflow: "auto",
  },
}))

const ItemsList = () => {
  const classes = useStyles()
  const [state, dispatch] = useReducer(itemsReducer, {
    parent: null,
    items: [],
  })
  const { currentUser, warehouse } = useContext(GlobalContext)
  const [sections1, setSections1] = useState([])
  const [sections2, setSections2] = useState([])
  const [categories, setCategories] = useState([])
  const [brands, setBrands] = useState([])
  const [selectedSection1SKU, setSelectedSection1SKU] = useState("")
  const [selectedSection2SKU, setSelectedSection2SKU] = useState("")
  const [selectedCategorySKU, setSelectedCategorySKU] = useState("")
  const [selectedAncestorSKU, setSelectedAncestorSKU] = useState("")
  const [selectedBrandId, setSelectedBrandId] = useState("")
  const [open, setOpen] = useState(false)
  const [itemToEdit, setItemToEdit] = useState(null)
  const [itemToSetMaxInCart, setItemToSetMaxInCart] = useState(null)
  const [maxInCartDialogOpen, setMaxInCartDialogOpen] = useState(false)
  const [itemToSetDiscount, setItemToSetDiscount] = useState(null)
  const [totalCost, setTotalCost] = useState()
  //const [discountDialogOpen, setDiscountDialogOpen] = useState(false)
  //const [discountToEdit, setDiscountToEdit] = useState()

  useEffect(() => {
    loadItems()
    loadBrands()
    loadSections1()
  }, [])
  useEffect(() => {
    loadItems()
  }, [selectedBrandId])
  useEffect(() => {
    loadSections2()
  }, [selectedSection1SKU])

  useEffect(() => {
    if (selectedSection2SKU) {
      loadCategories()
    }
  }, [selectedSection2SKU])
  useEffect(() => {
    loadItems()
  }, [selectedAncestorSKU])

  useEffect(() => {
    calculateTotalCost()
  }, [state.items])

  const queryChanged = () => {
    dispatch({ type: "CLEAR_ITEMS" })
  }

  const categorySelected = (sku) => {
    let selectedParent = null
    categories.map((category) => {
      if (sku === category.sku) {
        selectedParent = category
      }
    })
    if (selectedParent) {
      dispatch({ type: "SET_PARENT", parent: selectedParent })
    }
    if (sku != selectedAncestorSKU) {
      queryChanged()
      setSelectedAncestorSKU(sku)
    }

    setSelectedCategorySKU(sku)
  }

  const section1Seclected = (sku) => {
    queryChanged()
    setSelectedSection1SKU(sku)
    setSelectedAncestorSKU(sku)
    setSelectedSection2SKU("")
    setSelectedCategorySKU("")
    dispatch({ type: "SET_PARENT", parent: null })
  }

  const section2Seclected = (sku) => {
    queryChanged()
    setSelectedSection2SKU(sku)
    setSelectedAncestorSKU(sku)
    setSelectedCategorySKU("")
    dispatch({ type: "SET_PARENT", parent: null })
  }

  const brandSeclected = (brandId) => {
    queryChanged()
    setSelectedBrandId(brandId)
  }

  const loadBrands = async () => {
    const params = {
      userLocale: currentUser.locale,
    }
    try {
      const response = await API.graphql(graphqlOperation(listBrands, params))
      let sortedBrands = response.data.listBrands.items.sort((a, b) => {
        return a.name > b.name ? 1 : -1
      })
      setBrands(sortedBrands)
    } catch (response) {
      console.log(response.errors)
    }
  }
  const loadSections1 = () => {
    loadSectionsFromDB()
      .then((data) => {
        setSections1(data.data.listSections.items)
      })
      .catch((data) => {
        console.log(data)
      })
  }

  const loadSections2 = () => {
    loadSectionsFromDB(selectedSection1SKU)
      .then((data) => {
        setSections2(data.data.listSections.items)
      })
      .catch((data) => {
        console.log(data)
      })
  }

  const loadCategories = () => {
    loadCategoriesFromDB(selectedSection2SKU)
      .then((data) => {
        setCategories(data.data.listCategories.items)
      })
      .catch((data) => {
        console.log(data)
      })
  }

  async function loadSectionsFromDB(selectedParent) {
    let params = { userLocale: currentUser.locale }
    if (selectedParent) {
      params["parent"] = selectedParent
    }
    return API.graphql(graphqlOperation(listSections, params))
  }

  async function loadCategoriesFromDB(selectedParent) {
    let params = { userLocale: currentUser.locale }
    params["parent"] = selectedParent
    return API.graphql(graphqlOperation(listCategories, params))
  }

  const loadItems = async () => {
    let params = {
      warehouseId: warehouse.id,
      userLocale: currentUser.locale,
      limit: 1000,
    }
    if (selectedAncestorSKU) {
      params["sku"] = selectedAncestorSKU
    }
    if (selectedBrandId) {
      params["brandId"] = selectedBrandId
    }
    try {
      let fetchedItems = []
      let nextToken = null
      do {
        const response = await API.graphql(
          graphqlOperation(listItemsBySection, params)
        )
        const batch = response.data.listItemsBySection.items
        fetchedItems.push(...batch)
        nextToken = response.data.listItemsBySection.nextToken
        params.nextToken = nextToken
      } while (nextToken)
      dispatch({
        type: "LOAD_ITEMS",
        items: fetchedItems,
      })
    } catch (response) {
      console.log(response.errors)
    }
  }
  const editHandler = (item) => {
    setItemToEdit(item)
    setOpen(true)
  }

  const setMaxInCartHandler = (item) => {
    setItemToSetMaxInCart(item)
    setMaxInCartDialogOpen(true)
  }

  const saveHandler = async (modifiedItem) => {
    closeHandler()

    // let VATsValue = modifiedItem.VATsValue
    // modifiedItem.VATsValue = VATsValue
    // console.log(modifiedItem)
    const params = {
      sku: modifiedItem.sku,
      warehouseId: modifiedItem.warehouseId,
      listPrice: modifiedItem.listPrice,
      VATs: modifiedItem.VATs,
      VATsValue: modifiedItem.VATsValue,
      netPrice: modifiedItem.netPrice,
    }
    try {
      const response = await API.graphql(
        graphqlOperation(editItemPrice, params)
      )
      if (response.data.editItemPrice) {
        dispatch({ type: "UPDATE_ITEM", item: modifiedItem })
      } else {
        console.log("Item not modified")
      }
    } catch (response) {
      console.log(response.errors)
    }
  }

  const saveMaxInCartHandler = async (modifiedItem) => {
    closeMaxInCartHandler()
    let params = {
      sku: modifiedItem.sku,
      warehouseId: modifiedItem.warehouseId,
    }
    if (modifiedItem.maxInCart !== "") {
      params["maxInCart"] = modifiedItem.maxInCart
    }
    try {
      const response = await API.graphql(graphqlOperation(setMaxInCart, params))
      if (response.data.setMaxInCart) {
        dispatch({ type: "UPDATE_ITEM", item: modifiedItem })
      } else {
        console.log("Item not modified")
      }
    } catch (response) {
      console.log(response.errors)
    }
  }

  const closeHandler = () => {
    setOpen(false)
    setItemToEdit(null)
  }
  const closeMaxInCartHandler = () => {
    setMaxInCartDialogOpen(false)
    setItemToSetMaxInCart(null)
  }
  const addEditDiscount = async (item) => {
    setItemToSetDiscount(item)
    //setDiscountDialogOpen(true)
  }

  const closeDiscountHandler = () => {
    setItemToSetDiscount(null)
    //setDiscountToEdit(null)
  }

  const getBrandName = (brandId) => {
    if (!brandId) return ""
    for (let b of brands) {
      if (b.id === brandId) return b.name
    }
  }

  const exportToFBCatalog = () => {
    let rows = [
      [
        "id",
        "google_product_category",
        "gtin",
        "identifier_exists",
        "title",
        "link",
        "description",
        "brand",
        "size",
        "gender",
        "age_group",
        "color",
        "unit_pricing_base_measure",
        "unit_pricing_measure",
        "thumbnail",
        "image_link",
        "price",
        "sale_price",
        "sale_price_effective_date",
        "availability",
        "condition",
        "custom_label_0",
      ],
    ]
    state.items.map((item) => {
      const sec = item.sku.substring(0, 2)
      if (item.quantity > 0 && sec !== "50" && item.googleCategory) {
        const gtin = item.sku === item.barcode ? "" : item.barcode
        const identifierExists = gtin === "" ? "no" : "yes"
        const brandName = getBrandName(item.brandId)
        const cat = item.sku.substring(0, 6)
        const image = item.images ? item.images[0] : item.thumbnail
        const details = item.details ? item.details.replaceAll("\n", " ") : item.name
        let size = ""
        if(item.sku.startsWith("6030") || item.sku.startsWith("6010") || item.sku.startsWith("4060")){
          size = "one size"
        }
        let unitPricingBaseMeasure = ""
        let unitPricingMeasure = ""
        if(item.pricingBaseMeasureUnit){
          unitPricingBaseMeasure = `${item.pricingBaseMeasreValue}${item.pricingBaseMeasureUnit}`
          unitPricingMeasure = `${item.pricingMeasreValue}${item.pricingBaseMeasureUnit}`
        }
        const now = new Date()
        const today = now.toISOString()

        const imageLink = `https://aladdinimages124227-prod.s3-eu-west-1.amazonaws.com/public/${image}`
        const thumbnail = `https://aladdinimages124227-prod.s3-eu-west-1.amazonaws.com/public/${item.thumbnail}`
        rows.push([
          item.sku,
          item.googleCategory,
          gtin,
          identifierExists,
          `${item.name}`,
          `https://www.aladdinmarkt.de/ar/product/${item.sku}`,
          `${details}`,
          brandName,
          size,
          item.gender,
          item.ageGroup,
          item.colorName,
          unitPricingBaseMeasure,
          unitPricingMeasure,
          thumbnail,
          imageLink,
          `${item.listPrice} EUR`,
          item.discount ? `${item.finalPrice} EUR` : "",
          item.discount ? `${today}/${item.offerEndDate}` : "",
          "in stock",
          "new",
          cat,
        ])
      }
    })
    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(";")).join("\n")
    let encodedUri = encodeURI(csvContent)
    window.open(encodedUri)
  }

  const calculateTotalCost = () => {
    let cost = 0
    for (const item of state.items) {
      cost += item.quantity * item.cost
    }
    setTotalCost(cost)
  }

  return (
    <Paper>
      <Grid
        className={classes.grid}
        container
        spacing={3}
        justify="space-between"
      >
        <Grid item lg={3}>
          <FormControl className={classes.gridItem}>
            <InputLabel shrink htmlFor="brand-select">
              {I18n.get("label_brands")}
            </InputLabel>
            <Select
              onChange={(e) => brandSeclected(e.target.value)}
              value={selectedBrandId}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{
                id: "brand-select",
              }}
            >
              <MenuItem value="">--</MenuItem>
              {brands.map((brand, index) => (
                <MenuItem key={index} value={brand.id}>
                  {brand.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={3}>
          <FormControl className={classes.gridItem}>
            <InputLabel shrink htmlFor="section1-select">
              {I18n.get("label_section1")}
            </InputLabel>
            <Select
              onChange={(e) => section1Seclected(e.target.value)}
              value={selectedSection1SKU}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{
                id: "section1-select",
              }}
            >
              {sections1.map((section1, index) => (
                <MenuItem key={index} value={section1.sku}>
                  {section1.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={3}>
          <FormControl className={classes.gridItem}>
            <InputLabel shrink htmlFor="section2-select">
              {I18n.get("label_section2")}
            </InputLabel>
            <Select
              onChange={(e) => section2Seclected(e.target.value)}
              value={selectedSection2SKU}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{
                id: "section2-select",
              }}
            >
              {sections2.map((section2, index) => (
                <MenuItem key={index} value={section2.sku}>
                  {section2.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={3}>
          <FormControl className={classes.gridItem}>
            <InputLabel shrink htmlFor="category-select">
              {I18n.get("label_category")}
            </InputLabel>
            <Select
              onChange={(e) => categorySelected(e.target.value)}
              value={selectedCategorySKU}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{
                id: "category-select",
              }}
            >
              {categories.map((category, index) => (
                <MenuItem key={index} value={category.sku}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {!itemToSetDiscount && (
        <div className={classes.tableWrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={exportToFBCatalog}
            disabled={!state.items}
          >
            Export
          </Button>
          <Typography>
            {I18n.get("label_count")}: {state.items.length}
          </Typography>
          <Typography>
            {I18n.get("label_final_cost")}: {totalCost}
          </Typography>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell key="sku" style={{ width: 100 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {I18n.get("label_sku")}
                  </Typography>
                </TableCell>
                <TableCell key="name" style={{ minWidth: 200 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {I18n.get("label_full_name")}
                  </Typography>
                </TableCell>
                <TableCell key="quantity" style={{ width: 75 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {I18n.get("label_quantity")}
                  </Typography>
                </TableCell>
                <TableCell key="listPrice" style={{ width: 75 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {I18n.get("label_list_price")}
                  </Typography>
                </TableCell>
                <TableCell key="vat" style={{ width: 75 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {I18n.get("label_vat")}
                  </Typography>
                </TableCell>

                <TableCell key="vatValue" style={{ width: 75 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {I18n.get("label_vat_value")}
                  </Typography>
                </TableCell>
                <TableCell key="netPrice" style={{ width: 75 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {I18n.get("label_net_price")}
                  </Typography>
                </TableCell>
                <TableCell key="cost" style={{ width: 75 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {I18n.get("label_final_cost")}
                  </Typography>
                </TableCell>
                <TableCell key="discount" style={{ width: 150 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {I18n.get("label_discount")}
                  </Typography>
                </TableCell>
                <TableCell key="finalPrice" style={{ width: 75 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {I18n.get("label_final_price")}
                  </Typography>
                </TableCell>
                <TableCell key="finalNetPrice" style={{ width: 75 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {I18n.get("label_final_net_price")}
                  </Typography>
                </TableCell>
                <TableCell key="maxInCart" style={{ width: 100 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {I18n.get("label_max_in_cart")}
                  </Typography>
                </TableCell>
                <TableCell key="actions" style={{ width: 75 }}>
                  <Typography variant="subtitle1" color="textPrimary">
                    {I18n.get("action_item_setup")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.items.map((item) => (
                <Item
                  key={item.sku}
                  item={item}
                  editHandler={editHandler}
                  setMaxInCartHandler={setMaxInCartHandler}
                  addEditDiscount={addEditDiscount}
                />
              ))}
            </TableBody>
          </Table>
        </div>
      )}

      {itemToEdit && (
        <EditItemSetupDialog
          open={open}
          saveHandler={saveHandler}
          item={itemToEdit}
          closeHandler={closeHandler}
        />
      )}
      {itemToSetMaxInCart && (
        <SetMaxInCartDialo
          open={maxInCartDialogOpen}
          saveHandler={saveMaxInCartHandler}
          item={itemToSetMaxInCart}
          closeHandler={closeMaxInCartHandler}
        />
      )}
      {itemToSetDiscount && (
        <MangeItemDiscounts
          item={itemToSetDiscount}
          onClose={closeDiscountHandler}
          dispatch={dispatch}
        />
      )}
    </Paper>
  )
}
export default ItemsList

/*
{itemToSetDiscount && (
        <SetDiscountDialog
          open={discountDialogOpen}
          saveHandler={saveDiscountHandler}
          item={itemToSetDiscount}
          activeDiscount={discountToEdit}
          closeHandler={closeDiscountHandler}
          deleteHandler={deleteDiscountHandler}
        />
      )}
*/
