/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const checkCoupon = /* GraphQL */ `
  query CheckCoupon($code: String, $customerNumber: String!) {
    checkCoupon(code: $code, customerNumber: $customerNumber) {
      code
      countryCode
      couponTarget
      couponType
      createDate
      customerNumber
      customers
      description
      endDate
      isActive
      name
      orderTotalLimit
      orders
      ordersCount
      reusable
      sku
      value
      warehouseId
    }
  }
`;
export const evaluateFraud = /* GraphQL */ `
  query EvaluateFraud(
    $countryCode: String!
    $orderNumber: String!
    $warehouseId: String!
  ) {
    evaluateFraud(
      countryCode: $countryCode
      orderNumber: $orderNumber
      warehouseId: $warehouseId
    ) {
      level
      suspectedOrders {
        VATValues {
          category
          value
        }
        aladdinPaymentStatus
        billingAddress {
          city
          countryCode
          firstName
          houseNr
          lastName
          street
          zipCode
        }
        cancelReason
        countryCode
        couponCode
        couponName
        couponValue
        creditsPromotion
        customerFirstOrder
        customerNumber
        dealsValue
        invoice
        invoiceNumber
        ipAddress
        items {
          VAT
          VATValue
          barcode
          cost
          discount
          discountRate
          finalNetPrice
          finalPrice
          finalVatValue
          listPrice
          name
          netPrice
          offerId
          orderNumber
          quantity
          sku
        }
        itemsTotal
        mobileNumber
        orderDate
        orderNumber
        orderStatus
        orderTotal
        packets
        paymentType
        shipmentType
        shippingAddress {
          city
          countryCode
          firstName
          houseNr
          lastName
          street
          zipCode
        }
        shippingCost
        shippingDiscount
        shippingVatValue
        statuses {
          date
          employeeId
          employeeName
          orderNumber
          status
        }
        subtotal
        transactionReference
        verifyCustomer
        warehouseId
      }
    }
  }
`;
export const findCustomerByEmail = /* GraphQL */ `
  query FindCustomerByEmail($email: String!) {
    findCustomerByEmail(email: $email) {
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      countryCode
      credits
      customerNumber
      discounts
      email
      firstName
      friendshipCode
      hasOrders
      hasPendingPayment
      isMyFriendCodeUsed
      lastName
      lastOrderDate
      locale
      mobileNumber
      myFriendCode
      ordersCount
      preferredShippingType
      recommendations
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      trackOrder
      trust
      username
    }
  }
`;
export const findCustomerByMobileNumber = /* GraphQL */ `
  query FindCustomerByMobileNumber($mobileNumber: String!) {
    findCustomerByMobileNumber(mobileNumber: $mobileNumber) {
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      countryCode
      credits
      customerNumber
      discounts
      email
      firstName
      friendshipCode
      hasOrders
      hasPendingPayment
      isMyFriendCodeUsed
      lastName
      lastOrderDate
      locale
      mobileNumber
      myFriendCode
      ordersCount
      preferredShippingType
      recommendations
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      trackOrder
      trust
      username
    }
  }
`;
export const getAdyenPaymentMethods = /* GraphQL */ `
  query GetAdyenPaymentMethods(
    $channel: String!
    $countryCode: String!
    $currency: String!
    $customerNumber: String
    $locale: String!
    $orderTotal: Float!
    $username: String!
  ) {
    getAdyenPaymentMethods(
      channel: $channel
      countryCode: $countryCode
      currency: $currency
      customerNumber: $customerNumber
      locale: $locale
      orderTotal: $orderTotal
      username: $username
    )
  }
`;
export const getBounceRate = /* GraphQL */ `
  query GetBounceRate(
    $fromDate: String!
    $platforms: [String!]!
    $toDate: String
  ) {
    getBounceRate(fromDate: $fromDate, platforms: $platforms, toDate: $toDate)
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($sku: String!, $userLocale: String!) {
    getCategory(sku: $sku, userLocale: $userLocale) {
      description
      descriptionTranslations {
        locale
        translation
      }
      locales
      name
      nextChildSKU
      parent {
        description
        descriptionTranslations {
          locale
          translation
        }
        locales
        name
        nextChildSKU
        parent {
          description
          locales
          name
          nextChildSKU
          showChildren
          sku
          thumbnail
        }
        showChildren
        sku
        thumbnail
        translations {
          locale
          translation
        }
      }
      sku
      translations {
        locale
        translation
      }
    }
  }
`;
export const getColdItemsForUser = /* GraphQL */ `
  query GetColdItemsForUser(
    $customerNumber: String!
    $limit: Int
    $userLocale: String!
    $warehouseId: String!
  ) {
    getColdItemsForUser(
      customerNumber: $customerNumber
      limit: $limit
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      VATs
      VATsValue
      ageGroup
      availableQuantity
      barcode
      brandId
      colorName
      cost
      description
      details
      discount
      discountType
      expires
      finalNetPrice
      finalPrice
      finalVATValue
      gender
      googleCategory
      images
      listPrice
      locales
      maxInCart
      name
      netPrice
      offerEndDate
      offerId
      pricingBaseMeasreValue
      pricingBaseMeasureUnit
      pricingMeasreValue
      providerId
      quantity
      relatedItems
      size
      sku
      thumbnail
      warehouseId
    }
  }
`;
export const getCollectedOrders = /* GraphQL */ `
  query GetCollectedOrders($countryCode: String!, $warehouseId: String!) {
    getCollectedOrders(countryCode: $countryCode, warehouseId: $warehouseId) {
      VATValues {
        category
        value
      }
      aladdinPaymentStatus
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      cancelReason
      countryCode
      couponCode
      couponName
      couponValue
      creditsPromotion
      customerFirstOrder
      customerNumber
      dealsValue
      invoice
      invoiceNumber
      ipAddress
      items {
        VAT
        VATValue
        barcode
        cost
        discount
        discountRate
        finalNetPrice
        finalPrice
        finalVatValue
        listPrice
        name
        netPrice
        offerId
        orderNumber
        quantity
        sku
      }
      itemsTotal
      mobileNumber
      orderDate
      orderNumber
      orderStatus
      orderTotal
      packets
      paymentType
      shipmentType
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      shippingCost
      shippingDiscount
      shippingVatValue
      statuses {
        date
        employeeId
        employeeName
        orderNumber
        status
      }
      subtotal
      transactionReference
      verifyCustomer
      warehouseId
    }
  }
`;
export const getCountry = /* GraphQL */ `
  query GetCountry($countryCode: String!, $userLocale: String!) {
    getCountry(countryCode: $countryCode, userLocale: $userLocale) {
      VATs
      countryCode
      locales
      managerId
      managerName
      name
      paymentType
      shipmentCosts {
        cost
        threshold
      }
      translations {
        locale
        translation
      }
    }
  }
`;
export const getCountryByManager = /* GraphQL */ `
  query GetCountryByManager($managerId: String!, $userLocale: String!) {
    getCountryByManager(managerId: $managerId, userLocale: $userLocale) {
      VATs
      countryCode
      locales
      managerId
      managerName
      name
      paymentType
      shipmentCosts {
        cost
        threshold
      }
      translations {
        locale
        translation
      }
    }
  }
`;
export const getCustomerByNumber = /* GraphQL */ `
  query GetCustomerByNumber($customerNumber: String!) {
    getCustomerByNumber(customerNumber: $customerNumber) {
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      countryCode
      credits
      customerNumber
      discounts
      email
      firstName
      friendshipCode
      hasOrders
      hasPendingPayment
      isMyFriendCodeUsed
      lastName
      lastOrderDate
      locale
      mobileNumber
      myFriendCode
      ordersCount
      preferredShippingType
      recommendations
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      trackOrder
      trust
      username
    }
  }
`;
export const getCustomerByUsername = /* GraphQL */ `
  query GetCustomerByUsername($username: String!) {
    getCustomerByUsername(username: $username) {
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      countryCode
      credits
      customerNumber
      discounts
      email
      firstName
      friendshipCode
      hasOrders
      hasPendingPayment
      isMyFriendCodeUsed
      lastName
      lastOrderDate
      locale
      mobileNumber
      myFriendCode
      ordersCount
      preferredShippingType
      recommendations
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      trackOrder
      trust
      username
    }
  }
`;
export const getCustomerCreditsHistory = /* GraphQL */ `
  query GetCustomerCreditsHistory($customerNumber: String!) {
    getCustomerCreditsHistory(customerNumber: $customerNumber) {
      createDate
      credits
      customerNumber
      description
      employeeId
      employeeName
      friendNumber
      orderNumber
    }
  }
`;
export const getCustomersWhoRespondedToFriendCodeReminder = /* GraphQL */ `
  query GetCustomersWhoRespondedToFriendCodeReminder($createDate: String) {
    getCustomersWhoRespondedToFriendCodeReminder(createDate: $createDate) {
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      countryCode
      credits
      customerNumber
      discounts
      email
      firstName
      friendshipCode
      hasOrders
      hasPendingPayment
      isMyFriendCodeUsed
      lastName
      lastOrderDate
      locale
      mobileNumber
      myFriendCode
      ordersCount
      preferredShippingType
      recommendations
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      trackOrder
      trust
      username
    }
  }
`;
export const getDefaultWarehouse = /* GraphQL */ `
  query GetDefaultWarehouse {
    getDefaultWarehouse {
      address
      countryCode
      id
      managerId
      managerName
      name
    }
  }
`;
export const getDefaultWarehouseConfiguration = /* GraphQL */ `
  query GetDefaultWarehouseConfiguration($input: WarehouseConfigurationFilter) {
    getDefaultWarehouseConfiguration(input: $input) {
      VATs
      address
      countryCode
      countryName
      paymentType
      shipmentCosts {
        cost
        threshold
      }
      warehouseId
      warehouseName
    }
  }
`;
export const getDiscountsDetails = /* GraphQL */ `
  query GetDiscountsDetails(
    $countryCode: String!
    $discountType: DiscountType
    $endDate: String
    $fromValue: Float
    $legacyDescounts: Boolean
    $startDate: String
    $toValue: Float
    $userLocale: String
    $warehouseId: String!
  ) {
    getDiscountsDetails(
      countryCode: $countryCode
      discountType: $discountType
      endDate: $endDate
      fromValue: $fromValue
      legacyDescounts: $legacyDescounts
      startDate: $startDate
      toValue: $toValue
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      discount
      endDate
      id
      name
      sku
      soldItems {
        cost
        price
        quantity
      }
      startDate
      totalQuantity
    }
  }
`;
export const getDiscountsOfItem = /* GraphQL */ `
  query GetDiscountsOfItem(
    $countryCode: String!
    $sku: String!
    $warehouseId: String!
  ) {
    getDiscountsOfItem(
      countryCode: $countryCode
      sku: $sku
      warehouseId: $warehouseId
    ) {
      discount
      discountType
      employeeId
      employeeName
      endDate
      finalListPrice
      finalNetPrice
      isActive
      listPrice
      locales
      netPrice
      sku
      startDate
      warehouseId
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($username: String!) {
    getEmployee(username: $username) {
      address
      countryCode
      employeeName
      groups
      locale
      telephone
      username
      warehouseId
      warehouseName
    }
  }
`;
export const getFriendCodeReminders = /* GraphQL */ `
  query GetFriendCodeReminders {
    getFriendCodeReminders {
      createDate
      customers
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($sku: String!, $userLocale: String, $warehouseId: String!) {
    getItem(sku: $sku, userLocale: $userLocale, warehouseId: $warehouseId) {
      VATs
      VATsValue
      ageGroup
      availableQuantity
      barcode
      brandId
      colorName
      cost
      description
      details
      discount
      discountType
      expires
      finalNetPrice
      finalPrice
      finalVATValue
      gender
      googleCategory
      images
      listPrice
      locales
      maxInCart
      name
      netPrice
      offerEndDate
      offerId
      pricingBaseMeasreValue
      pricingBaseMeasureUnit
      pricingMeasreValue
      providerId
      quantity
      relatedItems
      size
      sku
      thumbnail
      warehouseId
    }
  }
`;
export const getItemByBarcodeByWarehouse = /* GraphQL */ `
  query GetItemByBarcodeByWarehouse(
    $barcode: String!
    $userLocale: String!
    $warehouseId: String!
  ) {
    getItemByBarcodeByWarehouse(
      barcode: $barcode
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      VATs
      VATsValue
      ageGroup
      availableQuantity
      barcode
      brandId
      colorName
      cost
      description
      details
      discount
      discountType
      expires
      finalNetPrice
      finalPrice
      finalVATValue
      gender
      googleCategory
      images
      listPrice
      locales
      maxInCart
      name
      netPrice
      offerEndDate
      offerId
      pricingBaseMeasreValue
      pricingBaseMeasureUnit
      pricingMeasreValue
      providerId
      quantity
      relatedItems
      size
      sku
      thumbnail
      warehouseId
    }
  }
`;
export const getItems = /* GraphQL */ `
  query GetItems(
    $skus: [String]!
    $userLocale: String!
    $warehouseId: String!
  ) {
    getItems(skus: $skus, userLocale: $userLocale, warehouseId: $warehouseId) {
      items {
        VATs
        VATsValue
        ageGroup
        availableQuantity
        barcode
        brandId
        colorName
        cost
        description
        details
        discount
        discountType
        expires
        finalNetPrice
        finalPrice
        finalVATValue
        gender
        googleCategory
        images
        listPrice
        locales
        maxInCart
        name
        netPrice
        offerEndDate
        offerId
        pricingBaseMeasreValue
        pricingBaseMeasureUnit
        pricingMeasreValue
        providerId
        quantity
        relatedItems
        size
        sku
        thumbnail
        warehouseId
      }
      total
    }
  }
`;
export const getItemsWithDiscount = /* GraphQL */ `
  query GetItemsWithDiscount(
    $fromValue: Float
    $limit: Int
    $page: Int
    $parent: String
    $toValue: Float
    $userLocale: String!
    $warehouseId: String!
  ) {
    getItemsWithDiscount(
      fromValue: $fromValue
      limit: $limit
      page: $page
      parent: $parent
      toValue: $toValue
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      items {
        VATs
        VATsValue
        ageGroup
        availableQuantity
        barcode
        brandId
        colorName
        cost
        description
        details
        discount
        discountType
        expires
        finalNetPrice
        finalPrice
        finalVATValue
        gender
        googleCategory
        images
        listPrice
        locales
        maxInCart
        name
        netPrice
        offerEndDate
        offerId
        pricingBaseMeasreValue
        pricingBaseMeasureUnit
        pricingMeasreValue
        providerId
        quantity
        relatedItems
        size
        sku
        thumbnail
        warehouseId
      }
      total
    }
  }
`;
export const getOccasion = /* GraphQL */ `
  query GetOccasion(
    $createDate: String!
    $userLocale: String!
    $warehouseId: String!
  ) {
    getOccasion(
      createDate: $createDate
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      createDate
      image
      imageTranslations {
        locale
        translation
      }
      isActive
      items
      lastActivationDate
      name
      nameTranslations {
        locale
        translation
      }
      warehouseId
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($orderNumber: String!) {
    getOrder(orderNumber: $orderNumber) {
      VATValues {
        category
        value
      }
      aladdinPaymentStatus
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      cancelReason
      countryCode
      couponCode
      couponName
      couponValue
      creditsPromotion
      customerFirstOrder
      customerNumber
      dealsValue
      invoice
      invoiceNumber
      ipAddress
      items {
        VAT
        VATValue
        barcode
        cost
        discount
        discountRate
        finalNetPrice
        finalPrice
        finalVatValue
        listPrice
        name
        netPrice
        offerId
        orderNumber
        quantity
        sku
      }
      itemsTotal
      mobileNumber
      orderDate
      orderNumber
      orderStatus
      orderTotal
      packets
      paymentType
      shipmentType
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      shippingCost
      shippingDiscount
      shippingVatValue
      statuses {
        date
        employeeId
        employeeName
        orderNumber
        status
      }
      subtotal
      transactionReference
      verifyCustomer
      warehouseId
    }
  }
`;
export const getOrderItems = /* GraphQL */ `
  query GetOrderItems($orderNumber: String!, $userLocale: String!) {
    getOrderItems(orderNumber: $orderNumber, userLocale: $userLocale) {
      VAT
      VATValue
      barcode
      cost
      discount
      discountRate
      finalNetPrice
      finalPrice
      finalVatValue
      listPrice
      name
      netPrice
      offerId
      orderNumber
      quantity
      sku
    }
  }
`;
export const getOrderItemsDiscountsSummary = /* GraphQL */ `
  query GetOrderItemsDiscountsSummary(
    $countryCode: String!
    $endDate: String
    $startDate: String
    $userLocale: String
    $warehouseId: String!
  ) {
    getOrderItemsDiscountsSummary(
      countryCode: $countryCode
      endDate: $endDate
      startDate: $startDate
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      avgCost
      avgDiscount
      avgPrice
      discounts {
        cost
        discount
        price
        quantity
      }
      name
      quantity
      sku
    }
  }
`;
export const getOrdersSummary = /* GraphQL */ `
  query GetOrdersSummary(
    $countryCode: String!
    $endDate: String
    $startDate: String
    $warehouseId: String!
  ) {
    getOrdersSummary(
      countryCode: $countryCode
      endDate: $endDate
      startDate: $startDate
      warehouseId: $warehouseId
    ) {
      avgGoodsCost
      avgGrossMargin
      avgItemsTotal
      avgNetMargin
      avgPaymentCost
      avgShippingCost
      avgTotal
      count
      goodsCost
      grossMargin
      grossMarginRate
      itemsTotal
      netMargin
      netMarginRate
      total
    }
  }
`;
export const getPlacedOrders = /* GraphQL */ `
  query GetPlacedOrders($countryCode: String!, $warehouseId: String!) {
    getPlacedOrders(countryCode: $countryCode, warehouseId: $warehouseId) {
      VATValues {
        category
        value
      }
      aladdinPaymentStatus
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      cancelReason
      countryCode
      couponCode
      couponName
      couponValue
      creditsPromotion
      customerFirstOrder
      customerNumber
      dealsValue
      invoice
      invoiceNumber
      ipAddress
      items {
        VAT
        VATValue
        barcode
        cost
        discount
        discountRate
        finalNetPrice
        finalPrice
        finalVatValue
        listPrice
        name
        netPrice
        offerId
        orderNumber
        quantity
        sku
      }
      itemsTotal
      mobileNumber
      orderDate
      orderNumber
      orderStatus
      orderTotal
      packets
      paymentType
      shipmentType
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      shippingCost
      shippingDiscount
      shippingVatValue
      statuses {
        date
        employeeId
        employeeName
        orderNumber
        status
      }
      subtotal
      transactionReference
      verifyCustomer
      warehouseId
    }
  }
`;
export const getPopularItems = /* GraphQL */ `
  query GetPopularItems(
    $customerNumber: String
    $limit: Int
    $userLocale: String!
    $warehouseId: String!
  ) {
    getPopularItems(
      customerNumber: $customerNumber
      limit: $limit
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      VATs
      VATsValue
      ageGroup
      availableQuantity
      barcode
      brandId
      colorName
      cost
      description
      details
      discount
      discountType
      expires
      finalNetPrice
      finalPrice
      finalVATValue
      gender
      googleCategory
      images
      listPrice
      locales
      maxInCart
      name
      netPrice
      offerEndDate
      offerId
      pricingBaseMeasreValue
      pricingBaseMeasureUnit
      pricingMeasreValue
      providerId
      quantity
      relatedItems
      size
      sku
      thumbnail
      warehouseId
    }
  }
`;
export const getProductByBarcode = /* GraphQL */ `
  query GetProductByBarcode($barcode: String!, $userLocale: String!) {
    getProductByBarcode(barcode: $barcode, userLocale: $userLocale) {
      ageGroup
      attributes
      attributesTranslations {
        locale
        translation
      }
      barcode
      brand {
        id
        locales
        name
        translations {
          locale
          translation
        }
      }
      colorName
      colorNameTranslations {
        locale
        translation
      }
      description
      descriptionTranslations {
        locale
        translation
      }
      details
      detailsTranslations {
        locale
        translation
      }
      gender
      googleCategory
      images
      locales
      name
      nameTranslations {
        locale
        translation
      }
      nextImageId
      parent {
        description
        descriptionTranslations {
          locale
          translation
        }
        locales
        name
        nextChildSKU
        parent {
          description
          locales
          name
          nextChildSKU
          showChildren
          sku
          thumbnail
        }
        sku
        translations {
          locale
          translation
        }
      }
      pricingBaseMeasreValue
      pricingBaseMeasureUnit
      pricingMeasreValue
      relatedProducts
      size
      sizeTranslations {
        locale
        translation
      }
      sku
      thumbnail
    }
  }
`;
export const getProductBySku = /* GraphQL */ `
  query GetProductBySku($sku: String!, $userLocale: String!) {
    getProductBySku(sku: $sku, userLocale: $userLocale) {
      ageGroup
      attributes
      attributesTranslations {
        locale
        translation
      }
      barcode
      brand {
        id
        locales
        name
        translations {
          locale
          translation
        }
      }
      colorName
      colorNameTranslations {
        locale
        translation
      }
      description
      descriptionTranslations {
        locale
        translation
      }
      details
      detailsTranslations {
        locale
        translation
      }
      gender
      googleCategory
      images
      locales
      name
      nameTranslations {
        locale
        translation
      }
      nextImageId
      parent {
        description
        descriptionTranslations {
          locale
          translation
        }
        locales
        name
        nextChildSKU
        parent {
          description
          locales
          name
          nextChildSKU
          showChildren
          sku
          thumbnail
        }
        sku
        translations {
          locale
          translation
        }
      }
      pricingBaseMeasreValue
      pricingBaseMeasureUnit
      pricingMeasreValue
      relatedProducts
      size
      sizeTranslations {
        locale
        translation
      }
      sku
      thumbnail
    }
  }
`;
export const getSecondLevelSectionsOfDiscountedItems = /* GraphQL */ `
  query GetSecondLevelSectionsOfDiscountedItems(
    $userLocale: String!
    $warehouseId: String!
  ) {
    getSecondLevelSectionsOfDiscountedItems(
      userLocale: $userLocale
      warehouseId: $warehouseId
    )
  }
`;
export const getSection = /* GraphQL */ `
  query GetSection($parent: String, $sku: String!, $userLocale: String!) {
    getSection(parent: $parent, sku: $sku, userLocale: $userLocale) {
      description
      descriptionTranslations {
        locale
        translation
      }
      locales
      name
      nextChildSKU
      parent {
        description
        descriptionTranslations {
          locale
          translation
        }
        locales
        name
        nextChildSKU
        parent {
          description
          locales
          name
          nextChildSKU
          showChildren
          sku
          thumbnail
        }
        showChildren
        sku
        thumbnail
        translations {
          locale
          translation
        }
      }
      showChildren
      sku
      thumbnail
      translations {
        locale
        translation
      }
    }
  }
`;
export const getShipment = /* GraphQL */ `
  query GetShipment(
    $countryCode: String!
    $orderNumber: String!
    $warehouseId: String!
  ) {
    getShipment(
      countryCode: $countryCode
      orderNumber: $orderNumber
      warehouseId: $warehouseId
    ) {
      carrier
      createdAt
      height
      length
      orderNumber
      shipmentLabel
      shipmentNumber
      weight
      width
    }
  }
`;
export const getSimilarItems = /* GraphQL */ `
  query GetSimilarItems(
    $limit: Int
    $sku: String!
    $userLocale: String!
    $warehouseId: String!
  ) {
    getSimilarItems(
      limit: $limit
      sku: $sku
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      VATs
      VATsValue
      ageGroup
      availableQuantity
      barcode
      brandId
      colorName
      cost
      description
      details
      discount
      discountType
      expires
      finalNetPrice
      finalPrice
      finalVATValue
      gender
      googleCategory
      images
      listPrice
      locales
      maxInCart
      name
      netPrice
      offerEndDate
      offerId
      pricingBaseMeasreValue
      pricingBaseMeasureUnit
      pricingMeasreValue
      providerId
      quantity
      relatedItems
      size
      sku
      thumbnail
      warehouseId
    }
  }
`;
export const getTopSellers = /* GraphQL */ `
  query GetTopSellers(
    $countryCode: String!
    $endDate: String
    $parent: String
    $startDate: String
    $userLocale: String
    $warehouseId: String!
  ) {
    getTopSellers(
      countryCode: $countryCode
      endDate: $endDate
      parent: $parent
      startDate: $startDate
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      avgCost
      avgPrice
      name
      quantity
      sku
    }
  }
`;
export const getUserEvents = /* GraphQL */ `
  query GetUserEvents($filter: EventFilterInput!) {
    getUserEvents(filter: $filter) {
      childrenCount
      countryCode
      eventAttributes
      eventDate
      eventMetrics
      eventType
      isUserRegistered
      platform
      sessionId
      userId
    }
  }
`;
export const getUserEventsByUser = /* GraphQL */ `
  query GetUserEventsByUser($filter: UserEventsFilterInput!) {
    getUserEventsByUser(filter: $filter) {
      childrenCount
      countryCode
      eventAttributes
      eventDate
      eventMetrics
      eventType
      isUserRegistered
      platform
      sessionId
      userId
    }
  }
`;
export const getUserRecommendations = /* GraphQL */ `
  query GetUserRecommendations(
    $customerNumber: String!
    $limit: Int
    $userLocale: String!
    $warehouseId: String!
  ) {
    getUserRecommendations(
      customerNumber: $customerNumber
      limit: $limit
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      VATs
      VATsValue
      ageGroup
      availableQuantity
      barcode
      brandId
      colorName
      cost
      description
      details
      discount
      discountType
      expires
      finalNetPrice
      finalPrice
      finalVATValue
      gender
      googleCategory
      images
      listPrice
      locales
      maxInCart
      name
      netPrice
      offerEndDate
      offerId
      pricingBaseMeasreValue
      pricingBaseMeasureUnit
      pricingMeasreValue
      providerId
      quantity
      relatedItems
      size
      sku
      thumbnail
      warehouseId
    }
  }
`;
export const getWarehouse = /* GraphQL */ `
  query GetWarehouse($id: String!) {
    getWarehouse(id: $id) {
      address
      countryCode
      id
      managerId
      managerName
      name
    }
  }
`;
export const getWarehouseByManager = /* GraphQL */ `
  query GetWarehouseByManager($countryCode: String!, $managerId: String!) {
    getWarehouseByManager(countryCode: $countryCode, managerId: $managerId) {
      address
      countryCode
      id
      managerId
      managerName
      name
    }
  }
`;
export const isFriendshipCodeExists = /* GraphQL */ `
  query IsFriendshipCodeExists($friendshipCode: String!) {
    isFriendshipCodeExists(friendshipCode: $friendshipCode) {
      exists
    }
  }
`;
export const listAssignedTicket = /* GraphQL */ `
  query ListAssignedTicket($countryCode: String!, $employeeId: String) {
    listAssignedTicket(countryCode: $countryCode, employeeId: $employeeId) {
      countryCode
      createDate
      customerLocale
      customerNumber
      employeeId
      employeeName
      messurement
      ticketId
      ticketStatus
    }
  }
`;
export const listBanners = /* GraphQL */ `
  query ListBanners(
    $countryCode: String!
    $isActive: Boolean
    $userLocale: String
    $warehouseId: String!
  ) {
    listBanners(
      countryCode: $countryCode
      isActive: $isActive
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      active
      appDestination
      countryCode
      id
      locales
      parameter
      warehouseId
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands($userLocale: String!) {
    listBrands(userLocale: $userLocale) {
      items {
        id
        locales
        name
        translations {
          locale
          translation
        }
      }
      nextToken
    }
  }
`;
export const listCartItems = /* GraphQL */ `
  query ListCartItems($customerNumber: String) {
    listCartItems(customerNumber: $customerNumber) {
      items {
        VATs
        VATsValue
        barcode
        cost
        discount
        finalNetPrice
        finalPrice
        finalVATValue
        listPrice
        name
        netPrice
        offerId
        providerId
        quantity
        sku
        thumbnail
      }
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories($parent: String!, $userLocale: String!) {
    listCategories(parent: $parent, userLocale: $userLocale) {
      items {
        description
        descriptionTranslations {
          locale
          translation
        }
        locales
        name
        nextChildSKU
        parent {
          description
          locales
          name
          nextChildSKU
          showChildren
          sku
          thumbnail
        }
        sku
        translations {
          locale
          translation
        }
      }
      nextToken
    }
  }
`;
export const listCategoriesExcerpt = /* GraphQL */ `
  query ListCategoriesExcerpt($parent: String!, $userLocale: String!) {
    listCategoriesExcerpt(parent: $parent, userLocale: $userLocale) {
      items {
        name
        parent
        sku
      }
    }
  }
`;
export const listCountries = /* GraphQL */ `
  query ListCountries($limit: Int, $nextToken: String, $userLocale: String!) {
    listCountries(
      limit: $limit
      nextToken: $nextToken
      userLocale: $userLocale
    ) {
      items {
        VATs
        countryCode
        locales
        managerId
        managerName
        name
        paymentType
        shipmentCosts {
          cost
          threshold
        }
        translations {
          locale
          translation
        }
      }
      nextToken
    }
  }
`;
export const listCoupons = /* GraphQL */ `
  query ListCoupons($filter: CouponFilter) {
    listCoupons(filter: $filter) {
      code
      countryCode
      couponTarget
      couponType
      createDate
      customerNumber
      customers
      description
      endDate
      isActive
      name
      orderTotalLimit
      orders
      ordersCount
      reusable
      sku
      value
      warehouseId
    }
  }
`;
export const listCustomerCartItems = /* GraphQL */ `
  query ListCustomerCartItems($customerNumber: String, $userLocale: String) {
    listCustomerCartItems(
      customerNumber: $customerNumber
      userLocale: $userLocale
    ) {
      items {
        VATs
        VATsValue
        barcode
        cost
        discount
        finalNetPrice
        finalPrice
        finalVATValue
        listPrice
        name
        netPrice
        offerId
        providerId
        quantity
        sku
        thumbnail
      }
    }
  }
`;
export const listCustomerFavoriteItemSkUs = /* GraphQL */ `
  query ListCustomerFavoriteItemSkUs($customerNumber: String!) {
    listCustomerFavoriteItemSKUs(customerNumber: $customerNumber)
  }
`;
export const listCustomerFavoriteItems = /* GraphQL */ `
  query ListCustomerFavoriteItems(
    $customerNumber: String!
    $userLocale: String!
    $warehouseId: String!
  ) {
    listCustomerFavoriteItems(
      customerNumber: $customerNumber
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      items {
        VATs
        VATsValue
        barcode
        cost
        discount
        finalNetPrice
        finalPrice
        finalVATValue
        listPrice
        name
        netPrice
        offerId
        providerId
        quantity
        sku
        thumbnail
      }
    }
  }
`;
export const listCustomerOrders = /* GraphQL */ `
  query ListCustomerOrders($customerNumber: String!) {
    listCustomerOrders(customerNumber: $customerNumber) {
      VATValues {
        category
        value
      }
      aladdinPaymentStatus
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      cancelReason
      countryCode
      couponCode
      couponName
      couponValue
      creditsPromotion
      customerFirstOrder
      customerNumber
      dealsValue
      invoice
      invoiceNumber
      ipAddress
      items {
        VAT
        VATValue
        barcode
        cost
        discount
        discountRate
        finalNetPrice
        finalPrice
        finalVatValue
        listPrice
        name
        netPrice
        offerId
        orderNumber
        quantity
        sku
      }
      itemsTotal
      mobileNumber
      orderDate
      orderNumber
      orderStatus
      orderTotal
      packets
      paymentType
      shipmentType
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      shippingCost
      shippingDiscount
      shippingVatValue
      statuses {
        date
        employeeId
        employeeName
        orderNumber
        status
      }
      subtotal
      transactionReference
      verifyCustomer
      warehouseId
    }
  }
`;
export const listDiscountsCampaigns = /* GraphQL */ `
  query ListDiscountsCampaigns(
    $endDate: String
    $startDate: String
    $warehouseId: String!
  ) {
    listDiscountsCampaigns(
      endDate: $endDate
      startDate: $startDate
      warehouseId: $warehouseId
    ) {
      campaignDate
      customerHasOrders
      customers
      discountTypes
    }
  }
`;
export const listDiscountsExcerpt = /* GraphQL */ `
  query ListDiscountsExcerpt(
    $fromValue: Float
    $limit: Int
    $page: Int
    $parent: String
    $toValue: Float
    $userLocale: String!
    $warehouseId: String!
  ) {
    listDiscountsExcerpt(
      fromValue: $fromValue
      limit: $limit
      page: $page
      parent: $parent
      toValue: $toValue
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      items {
        availableQuantity
        discount
        finalPrice
        listPrice
        name
        offerEndDate
        offerId
        sku
        thumbnail
        warehouseId
      }
      total
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        address
        countryCode
        employeeName
        groups
        locale
        telephone
        username
        warehouseId
        warehouseName
      }
      nextToken
    }
  }
`;
export const listInventoryItemsToBeExpired = /* GraphQL */ `
  query ListInventoryItemsToBeExpired(
    $expires: String!
    $warehouseId: String!
  ) {
    listInventoryItemsToBeExpired(
      expires: $expires
      warehouseId: $warehouseId
    ) {
      barcode
      cost
      discount
      expires
      finalCost
      id
      inventoryEntryId
      providerId
      quantity
      shippingCostShare
      sku
      warehouseId
    }
  }
`;
export const listItemsBySection = /* GraphQL */ `
  query ListItemsBySection(
    $brandId: String
    $limit: Int
    $nextToken: String
    $sku: String
    $userLocale: String!
    $warehouseId: String!
  ) {
    listItemsBySection(
      brandId: $brandId
      limit: $limit
      nextToken: $nextToken
      sku: $sku
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      items {
        VATs
        VATsValue
        ageGroup
        availableQuantity
        barcode
        brandId
        colorName
        cost
        description
        details
        discount
        discountType
        expires
        finalNetPrice
        finalPrice
        finalVATValue
        gender
        googleCategory
        images
        listPrice
        locales
        maxInCart
        name
        netPrice
        offerEndDate
        offerId
        pricingBaseMeasreValue
        pricingBaseMeasureUnit
        pricingMeasreValue
        providerId
        quantity
        relatedItems
        size
        sku
        thumbnail
        warehouseId
      }
      nextToken
    }
  }
`;
export const listItemsOfOccasion = /* GraphQL */ `
  query ListItemsOfOccasion(
    $limit: Int
    $occasionId: String!
    $page: Int
    $userLocale: String!
    $warehouseId: String!
  ) {
    listItemsOfOccasion(
      limit: $limit
      occasionId: $occasionId
      page: $page
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      items {
        VATs
        VATsValue
        ageGroup
        availableQuantity
        barcode
        brandId
        colorName
        cost
        description
        details
        discount
        discountType
        expires
        finalNetPrice
        finalPrice
        finalVATValue
        gender
        googleCategory
        images
        listPrice
        locales
        maxInCart
        name
        netPrice
        offerEndDate
        offerId
        pricingBaseMeasreValue
        pricingBaseMeasureUnit
        pricingMeasreValue
        providerId
        quantity
        relatedItems
        size
        sku
        thumbnail
        warehouseId
      }
      total
    }
  }
`;
export const listItemsToBeExpired = /* GraphQL */ `
  query ListItemsToBeExpired(
    $expires: String!
    $userLocale: String!
    $warehouseId: String!
  ) {
    listItemsToBeExpired(
      expires: $expires
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      VATs
      VATsValue
      ageGroup
      availableQuantity
      barcode
      brandId
      colorName
      cost
      description
      details
      discount
      discountType
      expires
      finalNetPrice
      finalPrice
      finalVATValue
      gender
      googleCategory
      images
      listPrice
      locales
      maxInCart
      name
      netPrice
      offerEndDate
      offerId
      pricingBaseMeasreValue
      pricingBaseMeasureUnit
      pricingMeasreValue
      providerId
      quantity
      relatedItems
      size
      sku
      thumbnail
      warehouseId
    }
  }
`;
export const listItemsWithDiscount = /* GraphQL */ `
  query ListItemsWithDiscount(
    $fromValue: Float
    $parent: String
    $toValue: Float
    $userLocale: String!
    $warehouseId: String!
  ) {
    listItemsWithDiscount(
      fromValue: $fromValue
      parent: $parent
      toValue: $toValue
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      availableQuantity
      discount
      finalPrice
      listPrice
      name
      offerEndDate
      offerId
      sku
      thumbnail
      warehouseId
    }
  }
`;
export const listItemsWithDiscountOfType = /* GraphQL */ `
  query ListItemsWithDiscountOfType(
    $discountType: DiscountType!
    $limit: Int
    $page: Int
    $parent: String
    $userLocale: String!
    $warehouseId: String!
  ) {
    listItemsWithDiscountOfType(
      discountType: $discountType
      limit: $limit
      page: $page
      parent: $parent
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      items {
        VATs
        VATsValue
        ageGroup
        availableQuantity
        barcode
        brandId
        colorName
        cost
        description
        details
        discount
        discountType
        expires
        finalNetPrice
        finalPrice
        finalVATValue
        gender
        googleCategory
        images
        listPrice
        locales
        maxInCart
        name
        netPrice
        offerEndDate
        offerId
        pricingBaseMeasreValue
        pricingBaseMeasureUnit
        pricingMeasreValue
        providerId
        quantity
        relatedItems
        size
        sku
        thumbnail
        warehouseId
      }
      total
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages($ticketId: String!) {
    listMessages(ticketId: $ticketId) {
      employeeId
      employeeName
      message
      sender
      sentDate
      ticketId
      type
    }
  }
`;
export const listNewItems = /* GraphQL */ `
  query ListNewItems(
    $limit: Int
    $page: Int
    $userLocale: String!
    $warehouseId: String!
  ) {
    listNewItems(
      limit: $limit
      page: $page
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      items {
        VATs
        VATsValue
        ageGroup
        availableQuantity
        barcode
        brandId
        colorName
        cost
        description
        details
        discount
        discountType
        expires
        finalNetPrice
        finalPrice
        finalVATValue
        gender
        googleCategory
        images
        listPrice
        locales
        maxInCart
        name
        netPrice
        offerEndDate
        offerId
        pricingBaseMeasreValue
        pricingBaseMeasureUnit
        pricingMeasreValue
        providerId
        quantity
        relatedItems
        size
        sku
        thumbnail
        warehouseId
      }
      total
    }
  }
`;
export const listNewTickets = /* GraphQL */ `
  query ListNewTickets($countryCode: String!, $customerLocale: String) {
    listNewTickets(countryCode: $countryCode, customerLocale: $customerLocale) {
      countryCode
      createDate
      customerLocale
      customerNumber
      employeeId
      employeeName
      messurement
      ticketId
      ticketStatus
    }
  }
`;
export const listOccasions = /* GraphQL */ `
  query ListOccasions(
    $isActive: Boolean
    $userLocale: String!
    $warehouseId: String!
  ) {
    listOccasions(
      isActive: $isActive
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      createDate
      image
      imageTranslations {
        locale
        translation
      }
      isActive
      items
      lastActivationDate
      name
      nameTranslations {
        locale
        translation
      }
      warehouseId
    }
  }
`;
export const listOrdersAsResposeToDiscountsCampaign = /* GraphQL */ `
  query ListOrdersAsResposeToDiscountsCampaign(
    $campaignDate: String!
    $warehouseId: String!
  ) {
    listOrdersAsResposeToDiscountsCampaign(
      campaignDate: $campaignDate
      warehouseId: $warehouseId
    ) {
      VATValues {
        category
        value
      }
      aladdinPaymentStatus
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      cancelReason
      countryCode
      couponCode
      couponName
      couponValue
      creditsPromotion
      customerFirstOrder
      customerNumber
      dealsValue
      invoice
      invoiceNumber
      ipAddress
      items {
        VAT
        VATValue
        barcode
        cost
        discount
        discountRate
        finalNetPrice
        finalPrice
        finalVatValue
        listPrice
        name
        netPrice
        offerId
        orderNumber
        quantity
        sku
      }
      itemsTotal
      mobileNumber
      orderDate
      orderNumber
      orderStatus
      orderTotal
      packets
      paymentType
      shipmentType
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      shippingCost
      shippingDiscount
      shippingVatValue
      statuses {
        date
        employeeId
        employeeName
        orderNumber
        status
      }
      subtotal
      transactionReference
      verifyCustomer
      warehouseId
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $limit: Int
    $nextToken: String
    $parent: String
    $userLocale: String!
  ) {
    listProducts(
      limit: $limit
      nextToken: $nextToken
      parent: $parent
      userLocale: $userLocale
    ) {
      items {
        ageGroup
        attributes
        attributesTranslations {
          locale
          translation
        }
        barcode
        brand {
          id
          locales
          name
        }
        colorName
        colorNameTranslations {
          locale
          translation
        }
        description
        descriptionTranslations {
          locale
          translation
        }
        details
        detailsTranslations {
          locale
          translation
        }
        gender
        googleCategory
        images
        locales
        name
        nameTranslations {
          locale
          translation
        }
        nextImageId
        parent {
          description
          locales
          name
          nextChildSKU
          sku
        }
        pricingBaseMeasreValue
        pricingBaseMeasureUnit
        pricingMeasreValue
        relatedProducts
        size
        sizeTranslations {
          locale
          translation
        }
        sku
        thumbnail
      }
      nextToken
    }
  }
`;
export const listProductsToBeItems = /* GraphQL */ `
  query ListProductsToBeItems(
    $limit: Int
    $nextToken: String
    $parent: String
    $userLocale: String!
    $warehouseId: String
  ) {
    listProductsToBeItems(
      limit: $limit
      nextToken: $nextToken
      parent: $parent
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      items {
        ageGroup
        attributes
        attributesTranslations {
          locale
          translation
        }
        barcode
        brand {
          id
          locales
          name
        }
        colorName
        colorNameTranslations {
          locale
          translation
        }
        description
        descriptionTranslations {
          locale
          translation
        }
        details
        detailsTranslations {
          locale
          translation
        }
        gender
        googleCategory
        images
        locales
        name
        nameTranslations {
          locale
          translation
        }
        nextImageId
        parent {
          description
          locales
          name
          nextChildSKU
          sku
        }
        pricingBaseMeasreValue
        pricingBaseMeasureUnit
        pricingMeasreValue
        relatedProducts
        size
        sizeTranslations {
          locale
          translation
        }
        sku
        thumbnail
      }
      nextToken
    }
  }
`;
export const listProviders = /* GraphQL */ `
  query ListProviders {
    listProviders {
      items {
        address
        contactName
        id
        providerName
        telephone
        website
      }
    }
  }
`;
export const listRunOutShelves = /* GraphQL */ `
  query ListRunOutShelves($userLocale: String!, $warehouseId: String!) {
    listRunOutShelves(userLocale: $userLocale, warehouseId: $warehouseId) {
      items {
        barcode
        discountType
        inventoryEntries
        inventoryItems {
          barcode
          cost
          discount
          expires
          finalCost
          id
          inventoryEntryId
          providerId
          quantity
          shippingCostShare
          sku
          warehouseId
        }
        name
        offerId
        sku
      }
    }
  }
`;
export const listRunOutWarehouse = /* GraphQL */ `
  query ListRunOutWarehouse(
    $threshold: Int
    $userLocale: String!
    $warehouseId: String!
  ) {
    listRunOutWarehouse(
      threshold: $threshold
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      items {
        barcode
        name
        quantity
        sku
        warehouseId
      }
    }
  }
`;
export const listSections = /* GraphQL */ `
  query ListSections($parent: String, $userLocale: String!) {
    listSections(parent: $parent, userLocale: $userLocale) {
      items {
        description
        descriptionTranslations {
          locale
          translation
        }
        locales
        name
        nextChildSKU
        parent {
          description
          locales
          name
          nextChildSKU
          showChildren
          sku
          thumbnail
        }
        showChildren
        sku
        thumbnail
        translations {
          locale
          translation
        }
      }
      nextToken
    }
  }
`;
export const listSectionsExcerpt = /* GraphQL */ `
  query ListSectionsExcerpt($parent: String, $userLocale: String!) {
    listSectionsExcerpt(parent: $parent, userLocale: $userLocale) {
      items {
        name
        parent
        showChildren
        sku
        thumbnail
      }
    }
  }
`;
export const listTicketsByCustomer = /* GraphQL */ `
  query ListTicketsByCustomer($customerNumber: String!) {
    listTicketsByCustomer(customerNumber: $customerNumber) {
      countryCode
      createDate
      customerLocale
      customerNumber
      employeeId
      employeeName
      messurement
      ticketId
      ticketStatus
    }
  }
`;
export const listUnbalancedItems = /* GraphQL */ `
  query ListUnbalancedItems($warehouseId: String!) {
    listUnbalancedItems(warehouseId: $warehouseId) {
      availableQuantity
      inCartQuantity
      quantity
      sku
    }
  }
`;
export const listWarehousesByCountry = /* GraphQL */ `
  query ListWarehousesByCountry($countryCode: String!) {
    listWarehousesByCountry(countryCode: $countryCode) {
      items {
        address
        countryCode
        id
        managerId
        managerName
        name
      }
      nextToken
    }
  }
`;
export const makeAdyenPaymentDetailsCall = /* GraphQL */ `
  query MakeAdyenPaymentDetailsCall($paymentData: AWSJSON!) {
    makeAdyenPaymentDetailsCall(paymentData: $paymentData)
  }
`;
export const searchItems = /* GraphQL */ `
  query SearchItems(
    $limit: Int
    $page: Int
    $text: String!
    $userLocale: String!
    $warehouseId: String!
  ) {
    searchItems(
      limit: $limit
      page: $page
      text: $text
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      items {
        VATs
        VATsValue
        ageGroup
        availableQuantity
        barcode
        brandId
        colorName
        cost
        description
        details
        discount
        discountType
        expires
        finalNetPrice
        finalPrice
        finalVATValue
        gender
        googleCategory
        images
        listPrice
        locales
        maxInCart
        name
        netPrice
        offerEndDate
        offerId
        pricingBaseMeasreValue
        pricingBaseMeasureUnit
        pricingMeasreValue
        providerId
        quantity
        relatedItems
        size
        sku
        thumbnail
        warehouseId
      }
      total
    }
  }
`;
export const searchItemsByName = /* GraphQL */ `
  query SearchItemsByName(
    $limit: Int
    $name: String!
    $page: Int
    $userLocale: String!
    $warehouseId: String!
  ) {
    searchItemsByName(
      limit: $limit
      name: $name
      page: $page
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      items {
        VATs
        VATsValue
        ageGroup
        availableQuantity
        barcode
        brandId
        colorName
        cost
        description
        details
        discount
        discountType
        expires
        finalNetPrice
        finalPrice
        finalVATValue
        gender
        googleCategory
        images
        listPrice
        locales
        maxInCart
        name
        netPrice
        offerEndDate
        offerId
        pricingBaseMeasreValue
        pricingBaseMeasureUnit
        pricingMeasreValue
        providerId
        quantity
        relatedItems
        size
        sku
        thumbnail
        warehouseId
      }
      total
    }
  }
`;
export const searchItemsBySection = /* GraphQL */ `
  query SearchItemsBySection(
    $limit: Int
    $page: Int
    $parent: String
    $userLocale: String!
    $warehouseId: String!
  ) {
    searchItemsBySection(
      limit: $limit
      page: $page
      parent: $parent
      userLocale: $userLocale
      warehouseId: $warehouseId
    ) {
      items {
        VATs
        VATsValue
        ageGroup
        availableQuantity
        barcode
        brandId
        colorName
        cost
        description
        details
        discount
        discountType
        expires
        finalNetPrice
        finalPrice
        finalVATValue
        gender
        googleCategory
        images
        listPrice
        locales
        maxInCart
        name
        netPrice
        offerEndDate
        offerId
        pricingBaseMeasreValue
        pricingBaseMeasureUnit
        pricingMeasreValue
        providerId
        quantity
        relatedItems
        size
        sku
        thumbnail
        warehouseId
      }
      total
    }
  }
`;
export const searchOrders = /* GraphQL */ `
  query SearchOrders($input: SearchOrdersInput!) {
    searchOrders(input: $input) {
      VATValues {
        category
        value
      }
      aladdinPaymentStatus
      billingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      cancelReason
      countryCode
      couponCode
      couponName
      couponValue
      creditsPromotion
      customerFirstOrder
      customerNumber
      dealsValue
      invoice
      invoiceNumber
      ipAddress
      items {
        VAT
        VATValue
        barcode
        cost
        discount
        discountRate
        finalNetPrice
        finalPrice
        finalVatValue
        listPrice
        name
        netPrice
        offerId
        orderNumber
        quantity
        sku
      }
      itemsTotal
      mobileNumber
      orderDate
      orderNumber
      orderStatus
      orderTotal
      packets
      paymentType
      shipmentType
      shippingAddress {
        city
        countryCode
        firstName
        houseNr
        lastName
        street
        zipCode
      }
      shippingCost
      shippingDiscount
      shippingVatValue
      statuses {
        date
        employeeId
        employeeName
        orderNumber
        status
      }
      subtotal
      transactionReference
      verifyCustomer
      warehouseId
    }
  }
`;
export const validateAddress = /* GraphQL */ `
  query ValidateAddress($address: AddressInput!) {
    validateAddress(address: $address)
  }
`;
