import React from "react"

import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import ExtensionOutlinedIcon from "@material-ui/icons/ExtensionOutlined"
import EditOutlinedIcon from "@material-ui/icons/EditOutlined"

import IconButton from "@material-ui/core/IconButton"

const formatPrecentage = (value) => {
  const v = value * 100
  return `${Math.floor(v)} %`
}

const formatMaxInCart = (value) => {
  if (value) return value
  return "-"
}

const Item = ({ item, editHandler, setMaxInCartHandler, addEditDiscount }) => {
  return (
    <TableRow>
      <TableCell style={{ width: 100 }}>
        <Typography variant="body2">{item.sku}</Typography>
      </TableCell>
      <TableCell style={{ minWidth: 200 }}>
        <Typography variant="body2">{item.name}</Typography>
      </TableCell>
      <TableCell style={{ width: 75 }}>
        <Typography variant="body2">{item.quantity}</Typography>
      </TableCell>
      <TableCell style={{ width: 75 }}>
        <Typography variant="body2">{item.listPrice}</Typography>
      </TableCell>
      <TableCell style={{ width: 75 }}>
        <Typography variant="body2">{formatPrecentage(item.VATs)}</Typography>
      </TableCell>
      <TableCell style={{ width: 75 }}>
        <Typography variant="body2">{item.VATsValue}</Typography>
      </TableCell>
      <TableCell style={{ width: 75 }}>
        <Typography variant="body2">{item.netPrice}</Typography>
      </TableCell>
      <TableCell style={{ width: 75 }}>
        <Typography variant="body2">{item.cost}</Typography>
      </TableCell>
      <TableCell style={{ width: 150 }}>
        {formatPrecentage(item.discount)}
        <IconButton color="primary" onClick={(e) => addEditDiscount(item)}>
          <EditOutlinedIcon />
        </IconButton>
      </TableCell>
      <TableCell style={{ width: 75 }}>
        <Typography variant="body2">{item.finalPrice}</Typography>
      </TableCell>
      <TableCell style={{ width: 75 }}>
        <Typography variant="body2">{item.finalNetPrice}</Typography>
      </TableCell>
      <TableCell style={{ width: 100 }}>
        {formatMaxInCart(item.maxInCart)}
        <IconButton color="primary" onClick={(e) => setMaxInCartHandler(item)}>
          <EditOutlinedIcon />
        </IconButton>
      </TableCell>
      <TableCell style={{ width: 75 }}>
        <IconButton color="primary" onClick={(e) => editHandler(item)}>
          <ExtensionOutlinedIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default Item
