import React, { useEffect, useState } from "react"
import API, { graphqlOperation } from "@aws-amplify/api"

import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

import CustomerInfo from "../customer/CustomerInfo"
import CustomerOrdersPage from "../customer/CustomerOrdersPage"
import { getCustomerByNumber } from "../../graphql/queries"

const useStyles = makeStyles((theme) => ({
  info: {
    width: "30%",
  },
  orders: {
    width: "70%",
  },
  grid: {
    padding: theme.spacing(5),
    margin: theme.spacing(1),
  },
}))

const CustomerPage = (props) => {
  const {
    match: { params },
  } = props
  const customerNumber = params.customerNumber
  const classes = useStyles()
  const [customer, setCustomer] = useState()
  let subscribed = true
  useEffect(() => {
    getCustomer()
    return () => {
      subscribed = false
    }
  }, [])

  const getCustomer = async () => {
    const params = {
      customerNumber,
    }
    const response = await API.graphql(
      graphqlOperation(getCustomerByNumber, params)
    )
    const fetchedCustomer = response.data.getCustomerByNumber
    if (fetchedCustomer && subscribed) {
      setCustomer(fetchedCustomer)
    }
  }

  return (
    <React.Fragment>
      {customer && (
        <Grid
          className={classes.grid}
          container
          spacing={3}
          justify="space-between"
        >
          <Grid item xs={12} xl={3}>
            <CustomerInfo customer={customer} />
          </Grid>
          <Grid item xs={12} xl={9}>
            <CustomerOrdersPage customer={customer} />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

export default CustomerPage
