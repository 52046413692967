import React, { useContext, useReducer, useEffect, useState } from "react"

import { I18n } from "aws-amplify"
import { API, graphqlOperation } from "aws-amplify"

import GlobalContext from "../../context/global-context"
import ItemsContext from "../../context/items-context"
import itemsReducer from "../../reducers/items"

import { listRunOutWarehouse } from "../../graphql/queries"
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core"

const RundOutWarehouse = (props) => {
  const [{ runOutWarehouse }, dispatch] = useReducer(itemsReducer, {
    runOutWarehouse: [],
  })
  const { currentUser, warehouse } = useContext(GlobalContext)
  const [threshold, setThreshold] = useState(5)

  useEffect(() => {
    loadRundOutWarehouse()
  }, [])

  const loadRundOutWarehouse = async () => {
    const params = {
      warehouseId: warehouse.id,
      userLocale: currentUser.locale,
      threshold: threshold,
    }
    try {
      const response = await API.graphql(
        graphqlOperation(listRunOutWarehouse, params)
      )
      dispatch({
        type: "LOAD_RUN_OUT_WAREHOUSE",
        items: response.data.listRunOutWarehouse.items,
      })
    } catch (error) {
      console.log(error.errors)
    }
  }

  return (
    <Paper>
      <Grid container>
        <Grid item>
          <TextField
            variant="outlined"
            value={threshold}
            onChange={(e) => setThreshold(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={loadRundOutWarehouse}>
            {I18n.get("action_search")}
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{I18n.get("label_sku")}</TableCell>
              <TableCell>{I18n.get("label_name")}</TableCell>
              <TableCell>{I18n.get("label_quantity")}</TableCell>
              <TableCell>{I18n.get("label_cost")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {runOutWarehouse && (
              <React.Fragment>
                {runOutWarehouse.map((item, index) => (
                  <TableRow>
                    <TableCell>{item.sku}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>{item.cost}</TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default RundOutWarehouse
