import React, {useState, useContext, useEffect} from 'react'

import {I18n} from 'aws-amplify'
import API, {graphqlOperation} from '@aws-amplify/api'
import AWS from 'aws-sdk'
import awsmobile from '../../aws-exports'
import Aladdin from '../../aladdin-config'

import {createEmployee} from '../../graphql/mutations'
import EmployeesContext from '../../context/employees-context';


const aladdin = Aladdin()


const CreateEmployeeForm = ()=> {
    const [username, setUsername] = useState('')
    const [employeeName, setEmployeeName] = useState('')
    const [telephone, setTelephone] = useState('')
    const [address, setAddress] = useState('')
    const [street, setStreet] = useState('')
    const [houseNr, setHouseNr] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [city, setCity] = useState('')
    const [countryCode, setCountryCode] = useState('de')
    const [employeeLocale, setEmployeeLocale] = useState('de')
    const [position, setPosition] = useState('FF')
    const [warehouseId, setWarehouseId] = useState('')
    const [showWarehouse, setShowWarehouse] = useState(false)
    let  groups = []

    const {dispatch} = useContext(EmployeesContext)
    const clearForm = () => {
      setUsername('')
      setEmployeeName('')
      setAddress('')
      setStreet('')
      setHouseNr('')
      setZipCode('')
      setCity('')
      setCountryCode('de')
      setEmployeeLocale('de')
      setWarehouseId('')
      setTelephone('')
      setPosition('FF')
      groups = []
    }
  
    const createUserPoolUser = (createUserCallback)=>{
      
        AWS.config.credentials = new AWS.Credentials(
          aladdin.accessKeyId,
          aladdin.secretAccessKey
        );
       AWS.config.region = awsmobile.aws_project_region
       const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider()
        
       const params = {
            UserPoolId: awsmobile.aws_user_pools_id,
            Username: username,
            ForceAliasCreation: false,
            MessageAction: 'SUPPRESS',
            TemporaryPassword: '12345678',
            UserAttributes: [
              {
                Name: 'email',
                Value: username
              },
              {
                Name: 'email_verified',
                Value: 'true'
              },
              {
                Name: 'custom:config',
                Value: `{groups:[${groups}]}`
              }
            ]
          };
          cognitoidentityserviceprovider.adminCreateUser(params, (err, data) => {
            createUserCallback(err, data)
          });
    }
    async function createEmployeeInDB () {
      const createdEmployee = await API.graphql(graphqlOperation(createEmployee, {input:{
          username,
          employeeName,
          locale: employeeLocale,
          countryCode,
          address,
          telephone,
          groups,
          warehouseId
        }
      })).catch((data)=>{
        console.log(data.errors)
      })
      dispatch({type:'CREATE_EMPLOYEE', employee:{
        username: createdEmployee.data.createEmployee.username,
        countryCode: createdEmployee.data.createEmployee.countryCode,
        employeeName: createdEmployee.data.createEmployee.employeeName,
        locale: createdEmployee.data.createEmployee.locale,
        address: createdEmployee.data.createEmployee.address,
        telephone: createdEmployee.data.createEmployee.telephone,
        groups: createdEmployee.data.createEmployee.groups,
        warehouseId: createdEmployee.data.createEmployee.warehouseId
      }})
      clearForm()
      
    }

    const configureUserGroups = () => {
      switch(position){
        case 'GM':
          groups = ['GM', 'CM', 'WM', 'FF', 'CC']
          break
        case 'CM':
          groups = ['CM', 'WM', 'FF', 'CC']
          break
        case 'WM':
          groups = ['WM', 'FF', 'CC']
          break
        case 'FF':
          groups = ['FF']
          break
        case 'CC':
          groups = ['CC']
          break
        default:
          groups = ['FF']
      }
    }

    useEffect(()=>{
      if(countryCode && zipCode && city && street && houseNr){
        const fullAddress = `${countryCode.toUpperCase()}#${zipCode} ${city}#${street} ${houseNr}`
        setAddress(fullAddress)
      }
      
    }, [street, houseNr, zipCode, city, countryCode])

    const submitEmployee = (e) => {
      e.preventDefault()
      configureUserGroups()
      createUserPoolUser((err, data) => {
        if (err) console.log(err, err.stack); // an error occurred
        else     createEmployeeInDB()
      })
      
    }
    
    return (
      <form onSubmit={submitEmployee}>
        <h3>{I18n.get('create_employee')}</h3>
        <div className="input-row">
          <div className="input-group">
            <input className="text-input--long" value={username} placeholder={I18n.get('label_email')} onChange={(e)=> {setUsername(e.target.value)}} />
          </div>
          <span className="input-space--medium"></span>
          <div className="input-group">
            <input className="text-input--long" value={employeeName} placeholder={I18n.get('label_name')} onChange={(e)=> {setEmployeeName(e.target.value)}} />
          </div>
          <span className="input-space--medium"></span>
          <div className="input-group">
            <input className="text-input" value={telephone} placeholder={I18n.get('label_telephone')} onChange={(e)=> {setTelephone(e.target.value)}} />
          </div>
        </div>
        <div className="input-row">
          <div className="input-group">
            <input className="text-input--long" value={street} placeholder={I18n.get('label_street')} onChange={(e)=> {setStreet(e.target.value)}} />
          </div>
          <span className="input-space"></span>
          <div className="input-group">
            <input className="text-input--small" value={houseNr} placeholder={I18n.get('label_house_nr')} onChange={(e)=> {setHouseNr(e.target.value)}} />
          </div>
          <span className="input-space--long"></span>
          <div className="input-group">
              <input className="text-input--small" value={zipCode} placeholder={I18n.get('label_zip_code')} onChange={(e)=> {setZipCode(e.target.value)}} />
          </div>
          <span className="input-space"></span>
          <div className="input-group">
            <input className="text-input--long" value={city} placeholder={I18n.get('label_city')} onChange={(e)=> {setCity(e.target.value)}} />
          </div>
          
        </div>
        <div className="input-row">
          <div className="input-group">
            <label>{I18n.get('label_position')}</label>
            <select value={position} onChange={(e) => {setPosition(e.target.value)}}>
              {aladdin.roles.map((role) => (
                <option key={role.code} value={role.value}>{I18n.get(role.code)}</option>
              ))}
            </select>
          </div>
          <span className="input-space--medium"></span>
          {showWarehouse && <div className="input-group">
              <input value={warehouseId} placeholder={I18n.get('label_warehouse')} onChange={(e)=> {setWarehouseId(e.target.value)}} />
            </div>
          }
          
        </div>
        <div className="input-row">
          <div className="input-group">
            <label>{I18n.get('label_language')}</label>
            <select value={employeeLocale} onChange={(e) => {setEmployeeLocale(e.target.value)}}>
              {aladdin.languages.map((language) => (
                <option key={language.code} value={language.locale}>{I18n.get(language.code)}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="input-row">
          <button className="form-button">{I18n.get('action_save')}</button>
        </div>
      </form>

    )
}

export {CreateEmployeeForm as default}