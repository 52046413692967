import React, { useState, useReducer, useEffect } from "react"
import API, { graphqlOperation } from "@aws-amplify/api"
import {
  listCustomerOrders,
  getOrder,
  getOrderItems,
  evaluateFraud,
} from "../../graphql/queries"
import {
  setAladdinInvoicePaid,
  cancelOrder,
  setCustomerVerified,
} from "../../graphql/mutations"
import { I18n } from "aws-amplify"

import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

import ordersReducer from "../../reducers/orders"
import OrdersList from "../order/OrdersList"
import OrderDetails from "../order/OrderDetails"
import OrderItemsList from "../order/OrderItemsList"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core"
import FraudDetails from "./FraudDetails"
import { Fragment } from "react"

const useStyles = makeStyles((theme) => ({
  orderNumberInput: {
    direction: "ltr",
    margin: theme.spacing(1),
  },
  container: {
    maxHeight: 800,
    margin: theme.spacing(2),
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  statusGridItem: {
    width: 150,
  },
}))

const CustomerOrdersPage = ({ customer }) => {
  const classes = useStyles()
  const [orderNumber, setOrderNumber] = useState("")
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [fruadDetails, setFraudDetails] = useState(null)
  const [cancelReason, setCancelReason] = useState("")
  const [{ orders, orderItems }, dispatch] = useReducer(ordersReducer, {
    orders: [],
  })

  const customerNumber = customer.customerNumber

  let cancelReasons = []
  cancelReasons.push({
    label: `${I18n.get("cancel_reason_CUSTOMER")}`,
    value: "CUSTOMER",
  })
  cancelReasons.push({
    label: `${I18n.get("cancel_reason_FRAUD")}`,
    value: "FRAUD",
  })
  cancelReasons.push({
    label: `${I18n.get("cancel_reason_NOT_RESPONDING")}`,
    value: "NOT_RESPONDING",
  })

  useEffect(() => {
    loadCustomerOrders()
  }, [])

  useEffect(() => {
    if (selectedOrder) {
      loadOrderItems()
    }
  }, [selectedOrder])

  const getOrderByNumber = async () => {
    if (orderNumber === "") {
      loadCustomerOrders()
    } else {
      const args = {
        orderNumber,
      }
      try {
        const response = await API.graphql(graphqlOperation(getOrder, args))
        dispatch({ type: "LOAD_ORDERS", orders: [response.data.getOrder] })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const loadCustomerOrders = async () => {
    const args = {
      customerNumber,
    }
    try {
      const response = await API.graphql(
        graphqlOperation(listCustomerOrders, args)
      )
      dispatch({
        type: "LOAD_ORDERS",
        orders: response.data.listCustomerOrders,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const setOrderPaid = async () => {
    try {
      const args = {
        orderNumber: selectedOrder.orderNumber,
      }
      await API.graphql(graphqlOperation(setAladdinInvoicePaid, args))
      const response = await API.graphql(graphqlOperation(getOrder, args))
      const fetchedOrder = response.data.getOrder
      dispatch({ type: "UPDATE_ORDER", order: fetchedOrder })
      setSelectedOrder(fetchedOrder)
    } catch (error) {
      console.log(error)
    }
  }
  const customerVerified = async () => {
    try {
      const args = {
        orderNumber: selectedOrder.orderNumber,
        countryCode: selectedOrder.countryCode,
        warehouseId: selectedOrder.warehouseId,
      }
      const response = await API.graphql(
        graphqlOperation(setCustomerVerified, args)
      )
      const verified = response.data.setCustomerVerified
      if (verified) {
        const response = await API.graphql(graphqlOperation(getOrder, args))
        const fetchedOrder = response.data.getOrder
        dispatch({ type: "UPDATE_ORDER", order: fetchedOrder })
        setSelectedOrder(fetchedOrder)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteOrder = async () => {
    if (cancelReason === "") return
    try {
      const args = {
        orderNumber: selectedOrder.orderNumber,
        countryCode: selectedOrder.countryCode,
        warehouseId: selectedOrder.warehouseId,
        reason: cancelReason,
      }
      const response = await API.graphql(graphqlOperation(cancelOrder, args))
      const cancelled = response.data.cancelOrder
      if (cancelled) {
        const response = await API.graphql(graphqlOperation(getOrder, args))
        const fetchedOrder = response.data.getOrder
        dispatch({ type: "UPDATE_ORDER", order: fetchedOrder })
        setSelectedOrder(fetchedOrder)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const loadOrderItems = async () => {
    const args = {
      orderNumber: selectedOrder.orderNumber,
      userLocale: customer.locale,
    }
    try {
      const response = await API.graphql(graphqlOperation(getOrderItems, args))
      dispatch({
        type: "LOAD_ORDER_ITEMS",
        orderItems: response.data.getOrderItems,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetOrderDetails = (order) => {
    for (let o of orders) {
      if (o.orderNumber === order.orderNumber) {
        setSelectedOrder(o)
        break
      }
    }
  }

  const evaluateRiks = async () => {
    const params = {
      orderNumber: selectedOrder.orderNumber,
      countryCode: selectedOrder.countryCode,
      warehouseId: selectedOrder.warehouseId,
    }
    const response = await API.graphql(graphqlOperation(evaluateFraud, params))
    console.log(response.data.evaluateFraud)
    setFraudDetails(response.data.evaluateFraud)
  }

  const backToOrders = () => {
    setSelectedOrder(null)
  }

  return (
    <React.Fragment>
      {!selectedOrder && (
        <div className={classes.container}>
          <TextField
            className={classes.orderNumberInput}
            value={orderNumber}
            onChange={(e) => setOrderNumber(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={getOrderByNumber}
            color="primary"
          >
            {I18n.get("action_search")}
          </Button>
          <OrdersList orders={orders} clickHandler={handleGetOrderDetails} />
        </div>
      )}
      {selectedOrder && (
        <div className={classes.container}>
          <Button onClick={backToOrders} color="primary">
            {I18n.get("action_close")}
          </Button>
          <OrderDetails order={selectedOrder} />
          <div className={classes.actionsContainer}>
            {selectedOrder.paymentType === "ALADDIN_INVOICE" &&
              selectedOrder.aladdinPaymentStatus === "PENDING" &&
              !selectedOrder.verifyCustomer && (
                <Button
                  variant="contained"
                  onClick={setOrderPaid}
                  color="primary"
                >
                  {I18n.get("action_set_order_paid")}
                </Button>
              )}
            {selectedOrder.verifyCustomer && (
              <Button
                variant="contained"
                onClick={evaluateRiks}
                color="primary"
              >
                {I18n.get("action_evaluate_risk")}
              </Button>
            )}
            {selectedOrder.verifyCustomer && (
              <Button
                variant="contained"
                onClick={customerVerified}
                color="primary"
              >
                {I18n.get("action_set_customer_verified")}
              </Button>
            )}
            {selectedOrder.orderStatus === "PLACED" &&
              selectedOrder.paymentType === "ALADDIN_INVOICE" && (
                <React.Fragment>
                  <FormControl className={classes.statusGridItem}>
                    <InputLabel shrink htmlFor="reason-select">
                      {I18n.get("label_cancel_reason")}
                    </InputLabel>
                    <Select
                      onChange={(e) => setCancelReason(e.target.value)}
                      value={cancelReason}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{
                        id: "reason-select",
                      }}
                    >
                      <MenuItem value="">--</MenuItem>
                      {cancelReasons.map((reason, index) => (
                        <MenuItem key={index} value={reason.value}>
                          {reason.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    onClick={deleteOrder}
                    color="primary"
                    disabled={cancelReason === ""}
                  >
                    {I18n.get("action_cancel_order")}
                  </Button>
                </React.Fragment>
              )}
          </div>
          {fruadDetails && (
            <Fragment>
              {fruadDetails.length > 0 ? (
                <FraudDetails details={fruadDetails} />
              ) : (
                <Typography>{I18n.get("message_fraud_clear")}</Typography>
              )}
            </Fragment>
          )}
          {orderItems && <OrderItemsList orderItems={orderItems} />}
        </div>
      )}
    </React.Fragment>
  )
}

export default CustomerOrdersPage
