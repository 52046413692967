import React, { useEffect, useState } from "react"
import { MuiThemeProvider } from "@material-ui/core"
import CssBaseline from "@material-ui/core/CssBaseline"
import { create } from "jss"
import rtl from "jss-rtl"
import { StylesProvider, jssPreset } from "@material-ui/styles"
import "./styles/styles.scss"

import { withAuthenticator } from "aws-amplify-react"
import Amplify, { Auth, I18n } from "aws-amplify"
import API, { graphqlOperation } from "@aws-amplify/api"
import awsconfig from "./aws-exports"

import GlobalContext from "./context/global-context"
import { getEmployee, getDefaultWarehouse } from "./graphql/queries"
import dictionary from "./i18n/dictionary.json"
import AppRouter from "./routers/AppRouter"
import { AladdinTheme } from "./theme/AladdinTheme"

Amplify.configure(awsconfig)

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

function App() {
  const [currentUser, setCurrentUser] = useState()
  const [warehouse, setWarehouse] = useState()
  const [currentLocale, setCurrentLocale] = useState("ar")

  const loadWarehouse = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(getDefaultWarehouse, {})
      )
      setWarehouse(response.data.getDefaultWarehouse)
    } catch (response) {
      console.log(response.errors)
    }
  }

  async function loadEmployee() {
    console.log("Loading employee....")
    const user = await Auth.currentAuthenticatedUser().catch((err) =>
      console.log(err)
    )
    const username = user.attributes.email
    console.log(username)
    const employeeData = await API.graphql(
      graphqlOperation(getEmployee, { username })
    ).catch((data) => {
      console.log(data.errors)
    })
    console.log("Employee has been loaded")
    let loadedEmployee = employeeData.data.getEmployee
    configureCurrentUser(loadedEmployee)
  }

  const configureCurrentUser = (loadedEmployee) => {
    console.log("Configure current user")
    setCurrentUser(loadedEmployee)
    setCurrentLocale(loadedEmployee.locale)
    if (loadedEmployee.locale === "ar") {
      document.body.style.direction = "rtl"
      AladdinTheme.direction = "rtl"
    } else {
      document.body.style.direction = "ltr"
      AladdinTheme.direction = "ltr"
    }
    I18n.setLanguage(loadedEmployee.locale)
    I18n.putVocabularies(dictionary)
  }

  useEffect(() => {
    document.body.style.direction = "rtl"
    AladdinTheme.direction = "rtl"
    let isSubscribed = true
    if (isSubscribed) {
      loadEmployee()
      loadWarehouse()
    }
    return () => (isSubscribed = false)
  }, [])

  if (currentLocale === "ar") {
    return (
      <StylesProvider jss={jss}>
        <MuiThemeProvider theme={AladdinTheme}>
          <CssBaseline />
          {currentUser && warehouse && (
            <GlobalContext.Provider value={{ currentUser, warehouse }}>
              <AppRouter />
            </GlobalContext.Provider>
          )}
        </MuiThemeProvider>
      </StylesProvider>
    )
  } else {
    return (
      <MuiThemeProvider theme={AladdinTheme}>
        <CssBaseline />
        {currentUser && (
          <GlobalContext.Provider value={{ currentUser }}>
            <AppRouter />
          </GlobalContext.Provider>
        )}
      </MuiThemeProvider>
    )
  }
}

export default withAuthenticator(App)
//export default App
