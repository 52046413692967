import React, { Fragment, useState } from "react"
import API, { graphqlOperation } from "@aws-amplify/api"
import { getBounceRate } from "../../graphql/queries"
import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import funnels from "./funnels"

const useStyle = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    flip: false,
    textAlign: "left",
    direction: "ltr",
  },
  funnelContainer: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    flip: false,
    textAlign: "left",
    direction: "ltr",
    cursor: "pointer",
  },
  textField: {
    width: 200,
  },
  bounceRates: {
    marginTop: theme.spacing(2),
  },
}))
const AnalyticsPage = () => {
  const classes = useStyle()
  const history = useHistory()
  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()
  const [webBounceRate, setWebBounceRate] = useState("")
  const [androidBounceRate, setAndroidBounceRate] = useState("")
  const [bounceRate, setBounceRate] = useState("")

  const loadBounceRates = () => {
    if (!fromDate) return
    const params1 = {
      platforms: ["web"],
      fromDate: fromDate,
      toDate: toDate,
    }

    const params2 = {
      platforms: ["android"],
      fromDate: fromDate,
      toDate: toDate,
    }

    const params3 = {
      platforms: ["web", "android"],
      fromDate: fromDate,
      toDate: toDate,
    }
    API.graphql(graphqlOperation(getBounceRate, params1)).then((response) => {
      setWebBounceRate(response.data.getBounceRate)
    })
    API.graphql(graphqlOperation(getBounceRate, params2)).then((response) => {
      setAndroidBounceRate(response.data.getBounceRate)
    })
    API.graphql(graphqlOperation(getBounceRate, params3)).then((response) => {
      setBounceRate(response.data.getBounceRate)
    })
  }

  const toFunnel = (id) => {
    history.push(`/funnelDetails/${id}`)
  }
  const fromDateChanged = (e) => {
    setFromDate(e.target.value)
  }

  const toDateChanged = (e) => {
    setToDate(e.target.value)
  }

  const formatRate = (rate) => {
    if (rate === "") return ""
    const result = (rate * 100).toFixed(2)
    return `${result} %`
  }
  return (
    <Fragment>
      <Paper className={classes.container}>
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <TextField
              id="from_date"
              label="From"
              type="date"
              className={classes.textField}
              onChange={fromDateChanged}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              id="to_date"
              label="To"
              type="date"
              className={classes.textField}
              onChange={toDateChanged}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={loadBounceRates}
            >
              Load
            </Button>
          </Grid>
        </Grid>
        <div className={classes.bounceRates}>
          <Typography gutterBottom>
            Web Bounce Rate : {formatRate(webBounceRate)}
          </Typography>
          <Typography gutterBottom>
            Android Bounce Rate : {formatRate(androidBounceRate)}
          </Typography>
          <Typography gutterBottom>
            Overall Bounce Rate : {formatRate(bounceRate)}
          </Typography>
        </div>
      </Paper>
      {funnels.map((funnel, index) => (
        <Paper
          className={classes.funnelContainer}
          key={index}
          onClick={(e) => toFunnel(index)}
        >
          {funnel.name}
        </Paper>
      ))}
    </Fragment>
  )
}

export default AnalyticsPage
