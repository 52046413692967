// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:f38554fe-83ec-4172-a0c4-ac8297d1240b",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_m3uxUwGr3",
    "aws_user_pools_web_client_id": "18shd6pl8i3rtb2ou11fuu127i",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://s3uvdx5kwbcone6bbblrakvtnu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Aladdin-beta",
            "region": "eu-west-1"
        },
        {
            "tableName": "AladdinTicket-beta",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "aladdinimages122230-beta",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_mobile_analytics_app_id": "c9a660d1b74f434f91038c1925f3faad",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
