import React, { useState } from "react"
import API, { graphqlOperation } from "@aws-amplify/api"
import { getUserEvents, getUserEventsByUser } from "../../graphql/queries"
import { makeStyles } from "@material-ui/core/styles"
import { withStyles } from "@material-ui/core/styles"
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { getFormattedDateTime } from "../../utils/dateUtil"
import funnels from "./funnels"
import { Fragment } from "react"
const useStyle = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    flip: false,
    textAlign: "left",
    direction: "ltr",
  },
  textField: {
    width: 200,
  },
  accordionDetails: {
    display: "flex",
    flexDirection: "column",
  },
  tableContainer: {
    width: "100%",
    maxHeight: 400,
  },
  tableCell: {
    flip: false,
    textAlign: "left",
    direction: "ltr",
  },
  sessionInfo: {
    backgroundColor: theme.palette.primary.light,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  panelText: {
    fontSize: theme.typography.pxToRem(12),
  },
}))
const FunnelDetailsPage = (props) => {
  const classes = useStyle()
  const [series, setSeries] = useState([])
  const [eventsUniqueUsers, setEventsUniqueUsers] = useState([])
  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()
  const [usersEvents, setUsersEvents] = useState([])
  const [expanded, setExpanded] = useState("")
  const [beingLoaded, setBeingLoaded] = useState(false)
  const [uniqueUser, setUniqueUser] = useState(true)
  const {
    match: { params },
  } = props
  const funnelIndex = params.id
  const funnel = funnels[funnelIndex]
  const doFilter = () => {
    if (fromDate) {
      loadFunnelEvents()
    }
  }
  const loadFunnelEvents = async () => {
    const loadedSeries = []
    const loadedEventsUniqueUsers = []
    for (let funnelSerie of funnel.series) {
      const params = {
        filter: {
          eventType: funnelSerie,
          fromDate: fromDate,
          toDate: toDate,
          platforms: funnel.platforms,
        },
      }
      const response = await API.graphql(
        graphqlOperation(getUserEvents, params)
      )
      const events = response.data.getUserEvents
      loadedSeries.push({
        eventType: funnelSerie,
        events,
      })
      let eventUniqueUsersSet = new Set()
      for (const event of events) {
        eventUniqueUsersSet.add(event.userId)
      }
      loadedEventsUniqueUsers.push({
        eventType: funnelSerie,
        users: [...eventUniqueUsersSet],
      })
    }
    setSeries(loadedSeries)
    setEventsUniqueUsers(loadedEventsUniqueUsers)
  }

  const fromDateChanged = (e) => {
    setFromDate(e.target.value)
  }

  const toDateChanged = (e) => {
    setToDate(e.target.value)
  }

  const getRateOfPrevious = (count, index) => {
    if (index > 0) {
      if (uniqueUser) {
        const previousUsersCount = eventsUniqueUsers[index - 1].users.length
        if (previousUsersCount !== 0) {
          const rate = parseFloat(
            ((count / previousUsersCount) * 100).toFixed(2)
          )
          return `${rate} %`
        }
      } else {
        const previousEventsCount = series[index - 1].events.length
        if (previousEventsCount !== 0) {
          const rate = parseFloat(
            ((count / previousEventsCount) * 100).toFixed(2)
          )
          return `${rate} %`
        }
      }
    }

    return "-"
  }

  const getRateOfFirst = (count, index) => {
    if (index > 0) {
      if (uniqueUser) {
        const rate = parseFloat(
          ((count / eventsUniqueUsers[0].users.length) * 100).toFixed(2)
        )
        return `${rate} %`
      } else {
        const rate = parseFloat(
          ((count / series[0].events.length) * 100).toFixed(2)
        )
        return `${rate} %`
      }
    }

    return "-"
  }

  const showDropOffDetails = async (index) => {
    setBeingLoaded(true)
    const droppedOffs = uniqueUser
      ? getDroppedOffUsers(index)
      : getDroppedOffSessions(index)
    let usersMap = {}
    for (const userId of droppedOffs) {
      const params = {
        filter: {
          userId: userId,
          fromDate: fromDate,
          toDate: toDate,
          platforms: funnel.platforms,
        },
      }
      const response = await API.graphql(
        graphqlOperation(getUserEventsByUser, params)
      )
      const events = response.data.getUserEventsByUser
      for (const event of events) {
        const sessionId = event.sessionId
        let userData = usersMap[userId]
        if (!userData) {
          let sessionData = {}
          if (event.eventType === "_session.start") {
            sessionData.startDate = event.eventDate
            sessionData.platform = event.platform
            sessionData.events = []
          } else {
            sessionData.events = [event]
          }

          userData = {
            isRegistered: event.isUserRegistered,
          }
          userData[sessionId] = sessionData
          usersMap[userId] = userData
        } else {
          let sessionData = userData[sessionId]
          if (!sessionData) {
            sessionData = {}
            if (event.eventType === "_session.start") {
              sessionData.startDate = event.eventDate
              sessionData.platform = event.platform
              sessionData.events = []
            } else {
              sessionData.events = [event]
            }
          } else {
            if (event.eventType === "_session.start") {
              sessionData.startDate = event.eventDate
              sessionData.platform = event.platform
            } else {
              sessionData.events.push(event)
            }
          }

          userData[sessionId] = sessionData
          usersMap[userId] = userData
        }
      }
    }
    let usersList = []
    for (const userId of Object.keys(usersMap)) {
      const userMap = usersMap[userId]
      let sessions = []
      let userData = {
        userId,
      }
      for (const key of Object.keys(userMap)) {
        if (key !== "isRegistered") {
          sessions.push({
            sessionId: key,
            platform: userMap[key].platform,
            startDate: userMap[key].startDate,
            events: userMap[key].events,
          })
        } else {
          userData.isRegistered = userMap.isRegistered
        }
      }
      userData.sessions = sessions
      usersList.push(userData)
    }
    setUsersEvents(usersList)
    setBeingLoaded(false)
  }

  const getDroppedOffSessions = (index) => {
    const lsEvents = series[index - 1].events
    const csEvents = series[index].events
    const usersHasDroppedSession = new Set()
    for (let lsEvent of lsEvents) {
      let userHasDroppedSession = true
      for (let csEvent of csEvents) {
        if (lsEvent.sessionId === csEvent.sessionId) {
          userHasDroppedSession = false
          break
        }
      }
      if (userHasDroppedSession) {
        usersHasDroppedSession.add(lsEvent.userId)
      }
    }
    return usersHasDroppedSession
  }

  const getDroppedOffUsers = (index) => {
    const lsUsers = eventsUniqueUsers[index - 1].users
    const lcUsers = eventsUniqueUsers[index].users
    let droppedOffUsers = []
    for (const lsUser of lsUsers) {
      if (!lcUsers.includes(lsUser)) {
        droppedOffUsers.push(lsUser)
      }
    }
    return droppedOffUsers
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleUniqueUserChanged = (event) => {
    setUniqueUser(event.target.checked)
  }

  return (
    <React.Fragment>
      <Paper className={classes.container}>
        <Typography gutterBottom>{funnel.name}</Typography>
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <TextField
              id="from_date"
              label="From"
              type="date"
              className={classes.textField}
              onChange={fromDateChanged}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={2}>
            <TextField
              id="to_date"
              label="To"
              type="date"
              className={classes.textField}
              onChange={toDateChanged}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={uniqueUser}
                  onChange={handleUniqueUserChanged}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Unique Visitor?"
            />
          </Grid>
          <Grid item sm={3}>
            <Button variant="contained" color="primary" onClick={doFilter}>
              Filter
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={2}>
            Event Name
          </Grid>
          <Grid item sm={2}>
            Count
          </Grid>
          <Grid item sm={2}>
            % of Previous
          </Grid>
          <Grid item sm={2}>
            % of first
          </Grid>
          <Grid item sm={2}>
            Drop Off Details
          </Grid>
        </Grid>
        {uniqueUser ? (
          <Fragment>
            {eventsUniqueUsers.map((event, index) => (
              <Grid container key={index} spacing={2}>
                <Grid item sm={2}>
                  {event.eventType}
                </Grid>
                <Grid item sm={2}>
                  {event.users.length}
                </Grid>
                <Grid item sm={2}>
                  <div>{getRateOfPrevious(event.users.length, index)}</div>
                </Grid>
                <Grid item sm={2}>
                  <div>{getRateOfFirst(event.users.length, index)}</div>
                </Grid>
                <Grid item sm={2}>
                  {index > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => showDropOffDetails(index)}
                    >
                      Show
                    </Button>
                  )}
                </Grid>
              </Grid>
            ))}
          </Fragment>
        ) : (
          <Fragment>
            {series.map((serie, index) => (
              <Grid container key={index} spacing={2}>
                <Grid item sm={2}>
                  {serie.eventType}
                </Grid>
                <Grid item sm={2}>
                  {serie.events.length}
                </Grid>
                <Grid item sm={2}>
                  <div>{getRateOfPrevious(serie.events.length, index)}</div>
                </Grid>
                <Grid item sm={2}>
                  <div>{getRateOfFirst(serie.events.length, index)}</div>
                </Grid>
                <Grid item sm={2}>
                  {index > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => showDropOffDetails(index)}
                    >
                      Show
                    </Button>
                  )}
                </Grid>
              </Grid>
            ))}
          </Fragment>
        )}
      </Paper>
      <Paper className={classes.container}>
        {beingLoaded && <CircularProgress color="primary" />}
        {usersEvents.map((userEvents, index) => {
          if (
            !(
              userEvents.sessions.length === 1 &&
              userEvents.sessions[0].events.length === 0
            )
          ) {
            return (
              <Accordion
                style={{ width: "100%" }}
                key={index}
                square
                expanded={expanded === userEvents.userId}
                onChange={handleChange(userEvents.userId)}
              >
                <AccordionSummary>
                  <Grid container spacing={1}>
                    <Grid item sm={2}>
                      <Typography className={classes.panelText}>
                        UserId: {userEvents.userId}
                      </Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography className={classes.panelText}>
                        Registered:{" "}
                        {userEvents.isRegistered === true ? "Yes" : "No"}
                      </Typography>
                    </Grid>
                    <Grid item sm={2}>
                      <Typography className={classes.panelText}>
                        #Sessions: {userEvents.sessions.length}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                {(userEvents.sessions.length > 1 ||
                  userEvents.sessions[0].events.length > 0) && (
                  <AccordionDetails className={classes.accordionDetails}>
                    {userEvents.sessions.map((session, index) => (
                      <React.Fragment key={index}>
                        <Grid
                          container
                          spacing={1}
                          className={classes.sessionInfo}
                        >
                          <Grid item sm={2}>
                            <Typography className={classes.panelText}>
                              Start:{" "}
                              {session.startDate
                                ? getFormattedDateTime(session.startDate)
                                : "-"}
                            </Typography>
                          </Grid>
                          <Grid item sm={2}>
                            <Typography className={classes.panelText}>
                              Platform:{" "}
                              {session.platform ? session.platform : "-"}
                            </Typography>
                          </Grid>
                          <Grid item sm={2}>
                            <Typography className={classes.panelText}>
                              #Events: {session.events.length}
                            </Typography>
                          </Grid>
                        </Grid>
                        {session.events.length > 0 && (
                          <TableContainer className={classes.tableContainer}>
                            <Table
                              stickyHeader
                              aria-label="sticky table"
                              className={classes.table}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell className={classes.tableCell}>
                                    Date
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    Event
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    Attributes
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    Metrics
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody></TableBody>
                              <TableBody>
                                {session.events.map((event, i) => (
                                  <TableRow key={i}>
                                    <TableCell className={classes.tableCell}>
                                      {getFormattedDateTime(event.eventDate)}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {event.eventType}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {event.eventAttributes}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {event.eventMetrics}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </React.Fragment>
                    ))}
                  </AccordionDetails>
                )}
              </Accordion>
            )
          }
        })}
      </Paper>
    </React.Fragment>
  )
}

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails)

export default FunnelDetailsPage
