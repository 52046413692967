import React, { useState, useContext } from "react"
import { history } from "../routers/AppRouter"
import { NavLink as RouterLink } from "react-router-dom"
import AppBar from "@material-ui/core/AppBar"
import CssBaseline from "@material-ui/core/CssBaseline"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import ListSubheader from "@material-ui/core/ListSubheader"

import MenuIcon from "@material-ui/icons/Menu"
import LanguageIcon from "@material-ui/icons/Language"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import FolderOpenOutlinedIcon from "@material-ui/icons/FolderOpenOutlined"
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline"
import CategoryOutlinedIcon from "@material-ui/icons/CategoryOutlined"
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined"
import AppsOutlinedIcon from "@material-ui/icons/AppsOutlined"
import FingerprintOutlinedIcon from "@material-ui/icons/FingerprintOutlined"
import BallotOutlinedIcon from "@material-ui/icons/BallotOutlined"
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined"
import ExtensionOutlinedIcon from "@material-ui/icons/ExtensionOutlined"
import DomainOutlinedIcon from "@material-ui/icons/DomainOutlined"
import PersonOutlineIcon from "@material-ui/icons/PersonOutline"
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline"
import TocIcon from "@material-ui/icons/Toc"
import ViewCarouselOutlinedIcon from "@material-ui/icons/ViewCarouselOutlined"

import { Auth, I18n } from "aws-amplify"

import GlobalContext from "../context/global-context"

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))

const ResponsiveDrawer = (props) => {
  const { container } = props
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = useState(false)

  const { currentUser } = useContext(GlobalContext)
  const userRoles = new Set(currentUser.groups)
  const isGM = userRoles.has("GM")
  const isCM = userRoles.has("CM")
  const isWM = userRoles.has("WM")
  const isCC = userRoles.has("CC")

  const handleSignOut = () => {
    Auth.signOut()
      .then(console.log("Logged out"))
      .catch((err) => console.log(err))
    history.push("/")
  }

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <RouterLink className="header__nav-item" to="/">
          <ListItem button>
            <ListItemIcon>
              <AppsOutlinedIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary={I18n.get("label_dashboard")} />
          </ListItem>
        </RouterLink>
      </List>
      {isWM && (
        <React.Fragment>
          <Divider />
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {I18n.get("label_management")}
              </ListSubheader>
            }
          >
            {isWM && (
              <RouterLink className="header__nav-item" to="/employees">
                <ListItem button>
                  <ListItemIcon>
                    <PeopleOutlineIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_employees")} />
                </ListItem>
              </RouterLink>
            )}
            {isGM && (
              <RouterLink className="header__nav-item" to="/countries">
                <ListItem button>
                  <ListItemIcon>
                    <LanguageIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_countries")} />
                </ListItem>
              </RouterLink>
            )}
            {isCM && (
              <RouterLink className="header__nav-item" to="/warehouses">
                <ListItem button>
                  <ListItemIcon>
                    <StoreOutlinedIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_warehouses")} />
                </ListItem>
              </RouterLink>
            )}
            {isWM && (
              <RouterLink className="header__nav-item" to="/orders">
                <ListItem button>
                  <ListItemIcon>
                    <TocIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_orders")} />
                </ListItem>
              </RouterLink>
            )}
          </List>
        </React.Fragment>
      )}
      {isGM && (
        <React.Fragment>
          <Divider />
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {I18n.get("label_catalog")}
              </ListSubheader>
            }
          >
            {isGM && (
              <RouterLink className="header__nav-item" to="/sections">
                <ListItem button>
                  <ListItemIcon>
                    <FolderOpenOutlinedIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_sections")} />
                </ListItem>
              </RouterLink>
            )}
            {isGM && (
              <RouterLink className="header__nav-item" to="/categories">
                <ListItem button>
                  <ListItemIcon>
                    <CategoryOutlinedIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_categories")} />
                </ListItem>
              </RouterLink>
            )}
            {isGM && (
              <RouterLink className="header__nav-item" to="/brands">
                <ListItem button>
                  <ListItemIcon>
                    <FingerprintOutlinedIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_brands")} />
                </ListItem>
              </RouterLink>
            )}
            {isGM && (
              <RouterLink className="header__nav-item" to="/providers">
                <ListItem button>
                  <ListItemIcon>
                    <DomainOutlinedIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_providers")} />
                </ListItem>
              </RouterLink>
            )}
            {isGM && (
              <RouterLink className="header__nav-item" to="/products">
                <ListItem button>
                  <ListItemIcon>
                    <BallotOutlinedIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_products")} />
                </ListItem>
              </RouterLink>
            )}
            {isGM && (
              <RouterLink className="header__nav-item" to="/occasions">
                <ListItem button>
                  <ListItemIcon>
                    <BallotOutlinedIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_occasions")} />
                </ListItem>
              </RouterLink>
            )}
          </List>
        </React.Fragment>
      )}
      {isWM && (
        <React.Fragment>
          <Divider />
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {I18n.get("label_items")}
              </ListSubheader>
            }
          >
            {isWM && (
              <RouterLink className="header__nav-item" to="/items-page">
                <ListItem button>
                  <ListItemIcon>
                    <DescriptionOutlinedIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_items")} />
                </ListItem>
              </RouterLink>
            )}
            {isWM && (
              <RouterLink className="header__nav-item" to="/item-setup">
                <ListItem button>
                  <ListItemIcon>
                    <ExtensionOutlinedIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_items_setup")} />
                </ListItem>
              </RouterLink>
            )}
          </List>
        </React.Fragment>
      )}
      {isCC && (
        <React.Fragment>
          <Divider />
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {I18n.get("label_customer_care")}
              </ListSubheader>
            }
          >
            {isCC && (
              <RouterLink className="header__nav-item" to="/tickets">
                <ListItem button>
                  <ListItemIcon>
                    <ChatBubbleOutlineIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_tickets")} />
                </ListItem>
              </RouterLink>
            )}
            {isCC && (
              <RouterLink className="header__nav-item" to="/customer">
                <ListItem button>
                  <ListItemIcon>
                    <PersonOutlineIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_customers")} />
                </ListItem>
              </RouterLink>
            )}
            {isCC && (
              <RouterLink className="header__nav-item" to="/contestOrder">
                <ListItem button>
                  <ListItemIcon>
                    <PersonOutlineIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={I18n.get("label_order_summary_contest")}
                  />
                </ListItem>
              </RouterLink>
            )}
          </List>
        </React.Fragment>
      )}
      {isWM && (
        <React.Fragment>
          <Divider />
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {I18n.get("label_marketing")}
              </ListSubheader>
            }
          >
            {isWM && (
              <RouterLink className="header__nav-item" to="/banners">
                <ListItem button>
                  <ListItemIcon>
                    <ViewCarouselOutlinedIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_banners")} />
                </ListItem>
              </RouterLink>
            )}
            {isWM && (
              <RouterLink className="header__nav-item" to="/coupons">
                <ListItem button>
                  <ListItemIcon>
                    <ViewCarouselOutlinedIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_coupons")} />
                </ListItem>
              </RouterLink>
            )}
            {isWM && (
              <RouterLink
                className="header__nav-item"
                to="/friendCodeReminders"
              >
                <ListItem button>
                  <ListItemIcon>
                    <ChatBubbleOutlineIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={I18n.get("label_friend_code_reminder")}
                  />
                </ListItem>
              </RouterLink>
            )}
            {isWM && (
              <RouterLink className="header__nav-item" to="/discountsCampaigns">
                <ListItem button>
                  <ListItemIcon>
                    <PersonOutlineIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={I18n.get("label_discounts_personalized_emails")}
                  />
                </ListItem>
              </RouterLink>
            )}
          </List>
        </React.Fragment>
      )}
      {isWM && (
        <React.Fragment>
          <Divider />
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {I18n.get("label_statistics")}
              </ListSubheader>
            }
          >
            {isWM && (
              <RouterLink className="header__nav-item" to="/ordersSummary">
                <ListItem button>
                  <ListItemIcon>
                    <ChatBubbleOutlineIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_orders_summary")} />
                </ListItem>
              </RouterLink>
            )}
            {isWM && (
              <RouterLink className="header__nav-item" to="/itemsSales">
                <ListItem button>
                  <ListItemIcon>
                    <ChatBubbleOutlineIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_items_sales")} />
                </ListItem>
              </RouterLink>
            )}
            {isWM && (
              <RouterLink className="header__nav-item" to="/discountsDetails">
                <ListItem button>
                  <ListItemIcon>
                    <ChatBubbleOutlineIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={I18n.get("label_discounts_details")} />
                </ListItem>
              </RouterLink>
            )}
            {isGM && (
              <RouterLink className="header__nav-item" to="/analytics">
                <ListItem button>
                  <ListItemIcon>
                    <ChatBubbleOutlineIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary="Analytics" />
                </ListItem>
              </RouterLink>
            )}
          </List>
        </React.Fragment>
      )}

      <Divider />
      <List>
        <ListItem
          button
          onClick={handleSignOut}
          key={I18n.get("action_log_out")}
        >
          <ListItemIcon>
            <ExitToAppIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary={I18n.get("action_log_out")} />
        </ListItem>
      </List>
    </div>
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Aladdin
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden lgUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  )
}

export default ResponsiveDrawer
